$green:#119147;
$white: #ffffff;
$darkred: #D41F22;

.live-day-container {
    min-width: 95% !important;
    min-height: 90% !important;
    .popup-dialog-headar {
      height: 50px;
      background-color: #313941;
      .header-text {
        font-size: 20px;
        font-weight: 500;
      }
      .popup-dialog-icon {
        margin: 5px;
        max-height: 40px;
        max-width: 40px;
      }
    }
    .map-radar {
      height: 100%;
    }
    .popup-dialog-close {
      background-color: #7E3112;
      color: $white;
      font-weight: 500;
      font-size: 1em;
      float: right;
      &:hover {
        background-color: $darkred !important;
      }
    }
    .md-nav-bar {
      // background-color: $darkgreen !important;
      overflow-x: scroll;
      height: fit-content !important;
      .md-button.md-accent {
        color: $green !important;
      }
      md-nav-ink-bar {
        background-color: $green;
        color: $green;
      }
    }
  
    .day-chart-block {
      margin: 2px 8px;
      overflow: hidden;
    }
    .md-select-value{
      min-width:100px;
    }
    .forward-options{
      padding-right: 50px;
    }
    .live-download__btn{
      padding-left:20px;
    }
    .day-download__btn{
      padding-right: 40px;
    }
    .live-day__form-container{
      padding:8px;
    }
    .day-sensor__select{
      max-width: 300px;
    }
  }
  
  .md-date-range-picker__calendar__grid {
    font-size: 14px;
  }
  .anomaly-plot-block {
    margin: 2px 8px;
    overflow: hidden;
  }
  