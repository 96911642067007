// staple
$break-small: 320px;
$break-medium: 1024px;
$break-large: 1280px;
$break-xlarge: 1440px;
$break-xxlarge: 1920px;
* {
  margin: 0;
  padding: 0;
}

html,
body {
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.md-tooltip {
  height: auto;
  background-color: $darkgreen !important;
  padding: 0;
  p {
    font-size: 12px;
    padding: 10px;
    font-weight: bold;
  }
  h5 {
    display: block;
    padding: 0;
    text-align: center;
    // background-color: white;
    height: auto !important;
    text-transform: capitalize;
    color: $brown;
    font-weight: bolder;
    font-size: 24px;
  }
}

table {
  height: 100%;
  width: 100%;
  table-layout: static;
  border-collapse: collapse;
}

.shadow {
  box-shadow: 2px 2px 2px $darkgreen !important;
}

.show-map {
  height: 95vh;
}

.hide-map {
  height: 0vh;
}

.md-button.md-fab:not([disabled]):hover {
  background-color: $green !important;
}

md-bottom-sheet {
  background-color: rgba(0, 0, 0, 0) !important;
  border: none !important;
}

.leaflet-touch .leaflet-control-layers-toggle {
  display: none !important;
}

.tabpanel div md-content {
  height: 67vh;
}

// end staple
md-tooltip .md-content {
  height: auto;
}

.info-button {
  // float: left;
  position: absolute;
  right: 1.9vw;
  z-index: 1;
  // padding-right: 27px;
  max-height: 0;
  top: 1vh;
  background-color: $green;
  @media (max-width: $break-xlarge) {
    top: 1vh;
  }
  .material-icons {
    font-size: 22px;
    color: $white;
  }
}

.info-chat {
  // float: left;
  position: absolute;
  right: 1.5vw;
  z-index: 1;
  padding-right: 10px;
  max-height: 0;
  top: 1vh;
  height: 30px;
  width: 30px;
  @media (max-width: $break-xlarge) {
    top: 1vh;
  }
  .material-icons {
    font-size: 22px;
  }
}

.info-box {
  max-height: 0;
  max-width: 15vw;
  padding-left: 0;
  overflow: hidden;
  position: absolute;
  top: -1vh;
  right: 30px;
  transition: 0.5s ease-out;
  border: 0.5vh solid $brown;
  & > p {
    padding: 0.25vh 0.75vh;
    font-size: 0.75em;
    margin: 0;
    font-weight: 400;
    line-height: normal;
    span {
      font-weight: 800;
      letter-spacing: 0.5px;
      display: block;
    }
  }
  &.info-box.small {
    max-height: 0;
    // overflow: hidden;
  }
  &.info-box.big {
    top: 0;
    // overflow: hidden;
    max-height: 50vh;
    z-index: 100;
  }
}

.info-box.big-add-active.ng-animate {
  max-height: 50vh;
}

.info-box.small-add-active.ng-animate {
  // transition:  0.3s ease-out max-height;
  max-height: 0vh;
}

.info-box.ng-hide-add,
.info-box.ng-hide-remove {
  // height: auto;
  //   -webkit-transition-property: height;
  //            transition-property: height;
  //    -webkit-transition-duration: 1s;
  //            transition-duration: 1s;
  //    -webkit-transition-timing-function: linear;
  //            transition-timing-function: linear;
}

.app-container {
}

md-dialog {
  .alert-map-container {
    height: 18vh !important;
    width: 70vw !important;
    overflow: hidden;
    display: block;
  }
}

.nav-component {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
  color: $white;
  width: 100%;
  // overflow: hidden;
  // padding-top: 0;
  // float: left;
  // display: inline-block;
  // box-shadow: 4px 4px 2px black;
  md-icon {
    margin: 0 5px;
    color: $white;
  }
  md-list-item {
    padding: 0;
    div {
      padding: 0;
      width: 15vw;
      height: 48px;
    }
  }
  .navlist-container {
    position: absolute;
    z-index: 1000000;
    width: 100vw;
  }
  .navlist {
    background-color: rgba(54, 86, 70, 0.75);
    border-radius: 3px;
    // padding-bottom: 5px;
    height: 0vh;
    overflow: hidden;
    transition: 200ms ease-in-out height;
    .menu-item {
      // display: block;
      // font-size: 1em;
      margin: 0.5vh 0 0.5vh 0;
      text-shadow: 2px 2px 2px $darkgreen;
      &:hover {
        font-size: 1.3em;
        background-color: $white;
        color: $darkgreen;
        text-shadow: 0px 0px 0px $darkgreen;
      }
      a:hover {
        text-decoration: none;
      }
    }
    .submenu {
      position: absolute;
      left: 15vw;
      top: 5.7vh;
      height: 0vh;
      overflow: hidden;
      // margin-top: -60px;
      z-index: 99;
      background-color: rgba(54, 86, 70, 0.95);
      box-shadow: -1px 0px 1px grey;
      transition: 0.3s ease-in height;
      font-size: 1.3rem;
      width: 11vw;
      padding: 0.5vh 0 0 0.5vw;
      // -webkit-transition: left 200ms linear;
      // -moz-transition: left 200ms linear;
      // -ms-transition: left 200ms linear;
      // transition: left 200ms linear;
      // border-radius: 2px;
      margin: 0 0 1.5vh 0;
      md-switch .md-container {
        // width: 2.5vw;
        // 			margin: 0;
      }
      .md-label {
        text-transform: capitalize;
        font-weight: bold;
        /* letter-spacing: 1px; */
        /* width: 5vw; */
        color: #f3f4f4;
        /* display: block; */
        /* word-break: break-word; */
        /* word-wrap: break-word; */
        /* white-space: normal; */
        /* font-size: 1rem; */
        /* line-height: 16px; */
        text-shadow: 2px 2px 2px #243e34;
        padding-left: 0vw;
        @media screen and (max-width: $break-medium) {
          // padding-left: 1.5vw;
        }
      }
      ul {
        list-style-type: none;
        li,
        li a {
          color: $white;
          font-weight: bold;
          text-transform: capitalize;
          padding: 2vh 0;
          &:hover {
            // background-color: $silver;
            color: $green-shade;
          }
        }
      }
      &.long {
        height: auto;
        width: 20vw;
      }
    }
    &.long {
      height: 323px;
    }
    .md-bar {
      background-color: $brown;
    }
    md-switch.md-checked.md-primary .md-bar {
      background-color: $silver;
    }
    .md-thumb {
      background-color: $silver;
    }
    md-switch.md-checked.md-primary .md-thumb {
      background-color: $green;
    }
  }
  .logo {
    // padding: 0;
    background-color: $green;
    md-icon {
      float: left;
      font-size: 38px;
      vertical-align: middle;
      text-align: center;
      width: auto;
      height: auto;
      padding: 5px;
      // margin: 1vh 0vw 0vh 0.5vw;
      @media (min-width: $break-xlarge) {
        // margin: 0.1vh 0vw 0vh 0.5vw;
        // font-size: 52px;
      }
    }
    h1,
    p {
      margin: 0;
      text-align: center;
    }
    .site-name {
      // font-size: x-large;

      text-transform: uppercase;
      font-weight: 700;
      font-size: 16px;
      padding: 1.4vh 0;
    }
    h6 {
      // font-size: x-large;
      font-weight: 700;
      //text-transform: uppercase;
      font-family: "Montserrat", sans-serif;
      background-color: $darkgreen;
      text-align: center;
      //padding: 0.5vh 0;
      margin: 0;
      padding-left: 50px;
    }
    p,
    md-input-container {
      padding-left: 5px;
      background-color: $silver;
      color: $darkgreen;
      text-align: center;
      margin: 0;
      font-weight: bold;
      right: 130px;
      position: absolute;
      top: 0.3vw;
    }
  }
  .greeting {
    font-weight: 600;
    padding-left: 50px;
    /* border-bottom: 1px solid #F3F4F4; */
    display: block;
    text-align: center;
    text-transform: capitalize;
    background-color: $green;
    margin-top: -2px;
    @media (min-width: $break-xlarge) {
      //margin-top: 15px;
    }
    h5 {
      margin: 5px 0;
    }
  }
  .nav-footer {
    background-color: $lightgreen;
    position: absolute;
    bottom: 5vh;
    padding-top: 0;
    width: 20vw;
    p {
      margin: 0;
    }
  }
  .md-menu-bar {
    display: inline-flex;
  }
  .nav-title {
    margin: 0.5vw 0 0 0px;
    // padding: 0 0.4vw 0 1vh;
    text-transform: uppercase;
    font-size: small;
    text-align: left;
    vertical-align: middle;
    &.disabled {
      cursor: not-allowed;
      color: grey;
    }
  }
  a {
    color: $white;
    line-height: 2vh;
  }
  .nav-chat {
    margin-top: 10px;
    margin-right: 9px;
  }
  br {
    display: none;
  }
  md-divider {
    background-color: $white;
  }
  div {
    img {
      max-width: 16px;
      max-height: 16px;
      margin-left: 0.5vw;
      margin-top: 6px;
      margin-right: 0.81vw;
      width: 70%;
      height: auto;
      &:hover {
        background-color: $green;
      }
    }
  }
}

.view-container {
  background-color: $silver;
  overflow-y: hidden;
  // height: 97vh;
  // max-height: 97vh;
  // background-color: $white;
  padding-left: 30px;
  max-width: 100vw;
  width: 100vw;
  margin: 0;
  padding: 0;
  float: left;
  .content-container {
    max-height: 95vh;
    overflow-x: hidden;
  }
}

.footer-component {
  display: none;
  // padding: 12px;
  // display: inline-block;
  background-color: rgba(24, 138, 72, 0.83);
  color: $white;
  // text-transform: uppercase;
  width: 100vw;
  height: 45px;
  padding-right: 10px;
  // text-align: center;
  position: absolute;
  bottom: 0;
  left: 0;
  // font-size: 10px;
  z-index: 3;
  .logo {
    color: #eef0f3;
    font-weight: 700;
    text-transform: uppercase;
    font-family: "Montserrat", sans-serif !important;
    font-size: 1.3em;
    margin-left: 16px;
  }
  .plug {
    font-size: 12px;
    font-weight: 500;
  }
  span {
    color: #eef0f3;
    font-weight: 700;
    text-transform: uppercase;
    font-family: "Montserrat", sans-serif !important;
    font-size: 1.2em;
    letter-spacing: 0.7px;
  }
  .plug-logo {
    margin-right: 8px;
    font-weight: 500;
  }
  // .smart-tag {
  // 	float:left;
  // 	text-transform: none;
  // 	padding-top: 4px;
  // }
}
.bottomsheet-control {
  position: absolute;
  p.hover-message {
    transition: 0.3s ease-out all;
    opacity: 0;
    /* margin-left: 50vw; */
    /* margin-top: -12vh; */
    /* display: inline-block; */
    position: absolute;
    left: 80vh;
    bottom: 3vh;
    width: 565px;
    &.show-text {
      opacity: 1;
    }
  }
  .bottomsheet-icon {
    background-color: darkgreen;
    color: white;
    font-size: 4rem;
    z-index: 920;
    position: absolute;
    border-radius: 4px;
    height: 42px;
    width: 42px;
    left: 48.4vw;
    // margin-left: -42px;
    md-icon {
      font-size: 4rem;
      color: $white;
      padding: 0 0 60px 0;
    }
    bottom: 0vh;
    @media (min-width: $break-medium) {
      bottom: 0vh;
    }
    @media (min-width: $break-xxlarge) {
      bottom: 0vh;
    }
  }
}

// material overwrite
md-tabs md-tabs-wrapper,
md-tab-content md-content {
  background-color: $green;
  h1 {
    color: $darkgreen;
  }
}

// end material overwrite
iframe {
  height: 80%;
  width: 100%;
  overflow: visible;
}

.frame-container {
  padding-top: 0px;
  display: inline-block;
  width: 30vw;
  height: 58.2vh;
  overflow: visible;
  h2 {
    text-align: center;
    margin-bottom: 0;
    color: white;
  }
  &.col-1 {
    width: -moz-available; /* WebKit-based browsers will ignore this. */
    width: -webkit-stretch; /* Mozilla-based browsers will ignore this. */
    width: stretch;
    height: 60vh;
    iframe {
      height: 100%;
      width: 100%;
      overflow: visible;
    }
    .title {
      text-align: center;
      margin: 0;
      line-height: 0.25vh;
      color: $white;
      font-size: 16px !important;
    }
  }
  &.col-2 {
    width: 58vw;
    height: 67vh;
    float: left;
  }
  &.col-3 {
    width: 28vw;
    height: 67vh;
    border-left: 0.1vw solid $darkgreen;
  }
  &.col-sheet {
    position: absolute;
    z-index: 25;
    top: 47vh;
    width: 90vw;
    height: 80vh;
    iframe {
      height: 100%;
      width: 100%;
      overflow: visible;
    }
    .title {
      text-align: center;
      margin: 0;
      line-height: 0.25vh;
    }
  }
}

.hydro-scale-legend {
  background-color: rgba(0, 0, 0, 0.1) !important;
  color: $white !important;
  div > span {
    text-transform: capitalize;
    color: $white;
  }
  .title {
    letter-spacing: 0.5px;
    text-transform: capitalize;
    text-shadow: 2px 1px 2px $darkgreen;
    font-weight: 400;
  }
  span {
    padding: 10px;
    display: block;
    width: 100%;
  }
}

.heatmap-legend,
.hydro-scale-legend {
  max-width: 12vw;
  margin: 0;
  position: absolute;
  right: 0vw;
  z-index: 935;
  background-color: rgba(255, 255, 255, 0.8);
  color: $black;
  padding: 0 1vw 1vh 1vw;
  top: 8vh;
  > .title {
    text-align: center;
    font-size: 1em;
    font-weight: bold;
    text-decoration: underline;
    padding-bottom: 5px;
  }
  .red {
    border-left: 0.5vw solid rgb(178, 18, 18);
    padding-left: 0.5vw;
  }
  .blue {
    border-left: 0.5vw solid rgb(20, 133, 204);
    padding-left: 0.5vw;
  }
  .green {
    border-left: 0.5vw solid rgb(84, 204, 20);
    padding-left: 0.5vw;
  }
  .yellow {
    border-left: 0.5vw solid #f9682a;
    padding-left: 0.5vw;
  }
  .black {
    border-left: 0.5vw solid rgb(0, 0, 0);
    padding-left: 0.5vw;
  }

  .v-red {
    border-left: 3px solid #ff0000;
    padding-left: 10px;
  }
  .v-yellow {
    border-left: 3px solid #ffff00;
    padding-left: 10px;
  }
  .v-green {
    border-left: 3px solid $green;
    padding-left: 10px;
  }
  .v-orange {
    border-left: 3px solid #ffa500;
    padding-left: 10px;
  }
  .v-purple {
    border-left: 3px solid #9400d3;
    padding-left: 10px;
  }
}

.map-container .angular-leaflet-map {
  .leaflet-marker-pane {
    z-index: 1000;
  }
  .leaflet-popup-pane {
    z-index: 1100;
  }
  min-height: 100vh;
  // width: 100vw;
  .leaflet-control-attribution {
    // display: ;
  }
}

.map-container {
  z-index: 0;
  min-height: calc(100vh);
  position: relative;
  .closeScreen {
    position: absolute;
    right: 0.5vw;
    top: 1vh;
    z-index: 1;
    color: white;
  }
}

.chart-container {
  z-index: 0;
  position: relative;
  .closeScreen {
    position: absolute;
    right: 0.5vw;
    top: 1vh;
    z-index: 1;
    color: white;
  }
}

.angular-leaflet-map,
.chart-container {
  -webkit-transition-property: margin-top;
  transition-property: margin-top;
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;
  -webkit-transition-timing-function: linear;
  transition-timing-function: linear;
}

.chart-container {
  height: 0vh;
  position: absolute;
  bottom: 0;
  // margin-top: 70px;
  // display: none;
}

.chart-viewer {
  border-radius: 15px;
  height: 0vw;
  position: relative;
  z-index: 0;
  width: 6vw;
  background-color: #58911e;
  margin-top: -10vh;
  .md-fab {
    color: $white;
    // height: 32px;
    // width: 32px;
    display: inline-block;
    background-color: green;
    i {
      font-size: 33px;
      line-height: 1.8;
    }
  }
}

.map-container.split + div > .dates {
  bottom: 74.5vh;
}

.map-container.split + div > .dates.ewp {
  bottom: 69.9vh;
}

.map-container.split + div > .heatmap-slider {
  bottom: 70vh;
}

.map-container.split .angular-leaflet-map {
  height: 0vh;
}

.chart-container.split {
  margin-top: 0vh;
  display: block;
  height: 68vh;
  width: 100vw;
  overflow-y: scroll;
  background-color: $green;
  border-top-width: 2px;
  border-top-color: $darkgreen;
  border-top-style: solid;
  z-index: 2;
  .graph {
    height: 45vh;
    width: 30vw;
    position: relative;
    overflow: hidden;
    float: left;
  }
  .md-virtual-repeat-container {
    min-height: 34vh;
    .repeated-item-vertical {
      display: inline-block;
      height: 25vh;
      // min-height: 68vh;
      min-width: 34vw;
      iframe {
        width: 30vw;
        height: 34vh;
        overflow: hidden;
      }
    }
  }
}

.angular-leaflet-map.ng-isolate-scope.leaflet-container.leaflet-touch.leaflet-fade-anim.leaflet-grab.leaflet-touch-drag.leaflet-touch-zoom {
  height: 95vh;
  overflow: hidden;
}

.closeChart {
  position: absolute;
  right: 0.5vw;
  z-index: 1000;
}

.ewp-legend {
  max-width: 12vw;
  margin: 0;
  position: absolute;
  right: 0vw;
  z-index: 935;
  background-color: rgba(255, 255, 255, 0.8);
  color: $black;
  padding: 0 1vw 1vh 1vw;
  top: 17.5vh;
  > .title {
    text-align: center;
    font-size: 1em;
    font-weight: bold;
    // text-decoration: underline;
    padding-bottom: 5px;
  }
  .red {
    border-left: 0.5vw solid rgb(178, 18, 18);
    padding-left: 0.5vw;
  }
  .blue {
    border-left: 0.5vw solid rgb(20, 133, 204);
    padding-left: 0.5vw;
  }
  .green {
    border-left: 0.5vw solid rgb(84, 204, 20);
    padding-left: 0.5vw;
  }
  .yellow {
    border-left: 0.5vw solid yellow;
    padding-left: 0.5vw;
  }
  .black {
    border-left: 0.5vw solid rgb(0, 0, 0);
    padding-left: 0.5vw;
  }
}

md-toast.md-default-theme .md-toast-content,
md-toast .md-toast-content {
  background-color: $primary;
  color: $white;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 1px;
  text-transform: capitalize;
  margin-top: 25px;
}

.md-button.md-default-theme.md-fab,
.md-button.md-fab {
  background-color: $darkgreen !important;
}

.md-calendar-day-header {
  table-layout: auto !important;
}

md-tabs.md-default-theme md-ink-bar,
md-tabs md-ink-bar {
  color: $green-shade;
  background-color: $green-shade;
}

md-tabs .md-tab.md-active {
  color: $green-shade;
}

.md-button.md-primary.md-raised {
  // background-color: $green;
  float: right;
  margin-left: 4px;
  // margin-bottom: 5vw;
}

.md-button.md-primary.md-raised:not([disabled]):hover {
  background-color: $darkgreen;
}

md-input-container:not(.md-input-invalid).md-input-focused .md-input {
  border-color: $darkgreen;
}

md-input-container:not(.md-input-invalid).md-input-focused label {
  color: $darkgreen !important;
}

md-select .md-select-icon {
  color: $darkgreen;
}

a.disabled {
  cursor: not-allowed;
  color: grey !important;
  text-decoration: none !important;
}

md-select .md-select-value {
  border-bottom-color: $green;
  color: $brown;
}
md-select .md-select-value.md-select-placeholder {
  color: $brown;
}

md-select:not([disabled]):focus .md-select-value {
  border-bottom-color: $darkgreen;
  color: rgba(0, 0, 0, 0.87);
}

.md-button.md-primary.md-raised:not([disabled]).md-focused {
  background-color: $green;
}

.md-calendar-date.md-calendar-selected-date
  .md-calendar-date-selection-indicator,
.md-calendar-date.md-calendar-selected-date
  .md-calendar-date-selection-indicator {
  background-color: $darkgreen !important;
}

.md-default-theme
  .md-calendar-date.md-calendar-selected-date
  .md-calendar-date-selection-indicator,
.md-calendar-date.md-calendar-selected-date
  .md-calendar-date-selection-indicator,
.md-default-theme
  .md-calendar-date.md-focus.md-calendar-selected-date
  .md-calendar-date-selection-indicator,
.md-calendar-date.md-focus.md-calendar-selected-date
  .md-calendar-date-selection-indicator {
  background-color: $darkgreen !important;
}

.md-button.md-icon-button md-icon {
  color: $brown;
}

.md-calendar-date-selection-indicator:hover {
  background: $green;
}
.md-datepicker-input {
  color: $brown;
  font-weight: 500;
}

.md-datepicker-triangle-button.md-button.md-icon-button {
  background-color: $white;
}

.md-datepicker-input-container {
  border-bottom-color: $brown;
}

md-input-container {
  padding: 1px;
}

md-input-container .md-input {
  background-color: $silver;
  color: $brown !important;
  border-color: $green;
}
md-input-container label {
  color: $brown;
}

md-input-container:not(.md-input-has-value) input:not(:focus) {
  background-color: transparent;
}

md-input-container input[type="text"] {
  border-radius: 3px;
}

.md-active {
  > .quality-type {
    color: $white;
    text-shadow: none;
  }
}

.quality-type {
  font-size: 7px;
  letter-spacing: 0.7px;
  text-align: right;
  display: block;
  color: grey;
  // text-shadow: 1px 1px 1px $darkgreen;
  line-height: 7px;
  margin: -6px 0 6px 0;
}

.last-date {
  font-size: 16px;
  text-align: right;
  display: block;
  color: $darkgreen;
  line-height: 2px;
  margin: -6px 0 6px 0;
}

.dashboard-name {
  font-size: 20px;
  font-weight: 500;
  color: white;
}

.md-padding {
  padding: 0;
}

.nav-settings {
  // position: absolute;
  // right: 0;
  // top: 8px;
}

.nav-notifications {
  // position: absolute;
  // right: 70px;
  // top: 8px;
}

.nav-reports {
  // position: absolute;
  // right: 35px;
  // top: 8px;
}

.set-image {
  max-width: 21px !important;
  max-height: 20px !important;
}

@mixin marching-ants-v2-init($ant-size, $ant-width, $speed, $id) {
  padding: 5px + $ant-width;
  margin: 20px;
  background-size: $ant-size $ant-width, $ant-size $ant-width,
    $ant-width $ant-size, $ant-width $ant-size;
  background-position: 0 0, 0 100%, 0 0, 100% 0;
  background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
  animation: marching-ants-#{$id} $speed;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-play-state: paused;
  &:hover,
  &.marching {
    animation-play-state: running;
  }
  &.reverse {
    animation-direction: reverse;
  }
}
@mixin marching-ants-v2-color($a, $b) {
  background-image: linear-gradient(to right, $a 50%, $b 50%),
    linear-gradient(to right, $a 50%, $b 50%),
    linear-gradient(to bottom, $a 50%, $b 50%),
    linear-gradient(to bottom, $a 50%, $b 50%);
}

@mixin marching-ants-v2-animation($ant-size, $id) {
  @keyframes marching-ants-#{$id} {
    0% {
      background-position: 0 0, 0 100%, 0 0, 100% 0;
    }
    100% {
      background-position: 2 * $ant-size 0, -2 * $ant-size 100%,
        0 -2 * $ant-size, 100% 2 * $ant-size;
    }
  }
}

.marching-ants {
  @include marching-ants-v2-init(20px, 2px, 4s, 1);

  &.bnw {
    @include marching-ants-v2-color(#fff, #000);
  }
  &.headline {
    @include marching-ants-v2-color(#00e5ee, #000);
    color: #000;
    text-align: center;
  }
  &.info {
    @include marching-ants-v2-color(#dd2, transparent);
    background-color: #ffa;
    color: #dd2;
    text-align: center;
  }
  &.warning {
    @include marching-ants-v2-init(20px, 2px, 3s, 2);
    @include marching-ants-v2-color(#b21212, #fff);
    color: #000;
    text-align: center;
    // background-color: #faa;
  }
}
md-tabs-canvas {
  background-color: $brown;
  // span{
  //     color:$white;;
  // }
}
md-tabs .md-tab {
  color: $white;
}

@include marching-ants-v2-animation(20px, 1);
@include marching-ants-v2-animation(20px, 2);
@include marching-ants-v2-animation(20px, 3);

.quality-moniter-container {
  .quality-select {
    md-radio-group:not([disabled]).md-primary .md-checked .md-off {
      border-color: $white;
    }
    md-radio-group:not([disabled]).md-primary .md-on {
      background-color: $white;
    }
    .md-label {
      color: $white;
      font-weight: 500;
    }
    .quality-list {
      margin-left: 5px;
    }
    background-color: $green;
  }
  .md-nav-bar {
    background-color: $darkgreen !important;
    .md-button.md-accent {
      color: $green-shade !important;
    }
    md-nav-ink-bar {
      background-color: $green-shade;
      color: $green-shade;
    }
  }

  .quality-info-box {
    max-height: 0;
    max-width: 15vw;
    padding-left: 0;
    overflow: hidden;
    position: absolute;
    top: -1vh;
    right: 40px;
    transition: 0.5s ease-out;
    border: 0.5vh solid $brown;
    & > p {
      padding: 0.25vh 0.75vh;
      font-size: 0.75em;
      margin: 0;
      font-weight: 500;
      line-height: normal;
      background-color: $white !important;
      span {
        font-weight: 800;
        letter-spacing: 0.5px;
        display: block;
      }
    }
    &.quality-info-box.small {
      max-height: 0;
    }
    &.quality-info-box.big {
      top: 0;
      max-height: 50vh;
      z-index: 100;
    }
  }

  md-icon {
    color: $white;
  }
}
.logout-dialog {
  width: 350px;
  height: fit-content;
  padding: 20px 0 15px 0;
  .logout-text {
    padding: 0 0 15px 0;
    font-size: 20px;
    font-weight: 500;
  }
  .logout-close {
    background-color: $green;
    color: $white;
    &:hover {
      background-color: $darkgreen !important;
    }
  }
  .user-logout {
    margin-left: 40px;
    background-color: $red;
    color: $white;
    &:hover {
      background-color: $darkgreen !important;
    }
  }
}

.confirm-dialog {
  height: fit-content;
  padding: 20px 10px 15px 10px;
  .logout-text {
    padding: 0 0 15px 0;
    font-size: 20px;
    font-weight: 500;
  }
  .logout-close {
    background-color: $green;
    color: $white;
    &:hover {
      background-color: $darkgreen !important;
    }
  }
  .user-logout {
    margin-left: 40px;
    background-color: $red;
    color: $white;
    &:hover {
      background-color: $darkgreen !important;
    }
  }
}

.cont_us-dialog {
  width: 350px;
  height: fit-content;
  padding: 16px;
  .cont-us-header {
    font-weight: 700;
    font-size: 16px;
    margin-bottom: 14px;
  }
  .email__block {
    padding: 8px;
    font-size: 14px;
  }
  .phone__block {
    padding: 8px;
  }
  a {
    color: black;
  }
  md-icon {
    margin-right: 14px;
  }
  .available-txt {
    margin-left: 40px;
  }
  .logout-close {
    background-color: $green;
    color: $white;
    &:hover {
      background-color: $darkgreen !important;
    }
  }
}

.aura-tooltip {
  background-color: white !important;
  color: black !important;
  padding: 0 4px;
  letter-spacing: 0.2px;
  font-size: 12px;
  sup {
    color: #5d5b5b;
    vertical-align: super;
    font-size: 6px;
  }
}

.sami-count {
  // position: absolute;
  // display: inline-block;
  top: -1px;
  right: 2px;
  color: #fff;
  background: #d41f22;
  position: absolute;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  transition: transform 0.2s ease-in-out;
  transform: scale(0.6);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
  width: 22px;
  height: 22px;
  line-height: 22px;
}
md-autocomplete {
  min-width: 240px;
}



.info-anomaly-dialog {
  width: 80vw; 
  max-width: 1200px; 
  min-width: 800px; 
  max-height: 80vh;
  border-radius: 10px;
  overflow: hidden;
  background-color: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
}

.info-anomaly-text {
  padding: 20px;
  font-size: 16px; 
  color: #333333;
  overflow-y: auto; 
  flex: 1; 
}
.info-anomaly-text h4 {
  margin-bottom: 15px; 
  margin-top: 20px;    
  font-size: 18px;   
  font-weight: bold;
}

.info-anomaly-header {
  background-color: #006400; 
  padding: 10px;
  color: #ffffff;
  text-align: center;
  font-size: 20px; 
  font-weight: bold;
}

.info-anomaly-footer {
  background-color: #ff4c4c; 
  padding: 10px;
  text-align: center;
}

.info-anomaly-close {
  background-color: #ff4c4c; 
  color: #ffffff;
  font-size: 16px; 
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.info-anomaly-close:hover {
  background-color: #e60000;
}

.custom-ul {
  padding-left: 20px;
}
