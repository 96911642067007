$primary: #4D5322;
$secondary: #ff9800;
$tertiary: #00b4a1;
$quaternary: #5923e3;
// $quinary: #;
// $senary: #;
// $septenary: #;
// $octonary: #;
// $nonary: #;
// $denary: #;



$white: #ffffff;
// $brown: #A68A62;
// $light-brown: #C0B795;
$light-brown-opque-heavy: rgba(192, 183, 149, 0.9);
$light-brown-opque-light: rgba(192, 183, 149, 0.6);
$dark-black: #000;
$black: #292B2F;
$blue: #1eb1f3;
$darkblue: #03304B;
$orange: #7E3112;

$veylightblue: #D3F4E4;
$secondaryblack: #393C42;
$secondaryblue: #076198;
$red: #D41F22;
$darkred: #D41F22;
$yellow: #F0D705;
$violet: #7777ff;
// $green: #57901D;
$lightgreen: #62FBE9;
// $darkgreen: #243E34;
// $secondarygreen: #44AEA2;

$break-sm-computer: 1024px;
$break-lg-computer: 1280px;
$break-xl-computer: 1440px;


$grey: #2F3131;
// $darkred: #A25046;


$green:#119147;
$secondarygreen:#313941;
$silver:#C0C0C0;
$brown:#313941;
$darkgreen:#313941;
$std-grey:#758185;


$green-shade:#97f979