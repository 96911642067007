$break-small: 320px;
$break-medium: 1024px;
$break-large: 1280px;
$break-xlarge: 1440px;
$break-xxlarge: 1920px;

.aq-dialog {
  text-shadow: 0px 1px 0px black;
  .md-dialog-content {
    padding: 0;
    margin: 0;
    max-width: 69.9vw;
  }
  h2 {
    font-size: 16px !important;
  }
  .md-toolbar-tools {
    background-color: $darkgreen !important;
  }
  .alert-map-container,
  .angular-leaflet-map {
    margin: 0 !important;
    margin-top: -38px !important;
    padding: 0 !important;
    min-width: 69.8vw;
    width: 69.8vw;
  }
  md-select {
    margin: 10px 95px 0px 0px;
    background-color: $green;
    width: 70vw;
    display: block;
    margin: -20px 95px 0px -1px !important;
    padding: 0px !important;
    padding-left: 16px !important;
    font-size: 24px;
    // border-top-left-radius: 10px;
    // border-top-right-radius: 10px;
  }
  .md-select-value,
  .md-select-icon {
    color: $white !important;
  }
  p {
    padding-left: 16px;
    background-color: $brown;
    color: $white;
    text-transform: capitalize;
    text-align: center;
    font-size: large !important;
    text-decoration: underline;
  }
  .receiptions {
    max-height: 10vh !important;
    overflow: scroll !important;
    padding: 0 16px;
    margin-top: -10px;
    background-color: $silver;
    color: white;
    md-card {
      max-width: 12vw;
      float: left;
      background-color: $brown;
      color: $white;
      padding: 10px 0;
      margin: 5px 15px;
      .title {
        background-color: $green;
        text-align: center;
        color: $white;
        margin: 0;
        margin-top: -10px;
      }
      .number {
        text-align: center;
      }
      .email {
        // background-color: $green;
        text-align: center;
        color: $white;
        padding: 0 20px; // margin: 0;
        // margin-top: -10px;
      }
    }
    md-dialog-actions {
      background-color: $brown;
    }
  }
  textarea {
    margin: 0 10px;
    border: none;
  }
}

.leaflet-container img {
  max-width: none !important;
  z-index: 0 !important;
}

.green-1 {
  color: black;
  background-color: #00ff00;
}

.green-2 {
  color: black;
  background-color: #7fff00;
}

.green-3 {
  color: black;
  background-color: #aaff00;
}

.yellow-1 {
  color: black;
  background-color: #ceff00;
}

.yellow-2 {
  color: black;
  background-color: #ffff00;
}

.yellow-3 {
  color: black;
  background-color: #ffe400;
}

.yellow-4 {
  background-color: #ff7f00;
}

.red-1 {
  background-color: #ff5500;
}

.red-2 {
  background-color: #ff3a00;
}

.red-3 {
  background-color: #ff0000;
}

.aq-attribution {
  color: rgb(60, 64, 67);
  background-color: rgb(241, 243, 244);
  padding: 0 4px;
  font-size: 9px;
  letter-spacing: 0.4;
  text-align: right;
  font-weight: 500;
}

// .leaflet-control-container {
//   display: none;
// }

.heatmap-display {
  //text-shadow: 0px 2px 1px $darkgreen;
  font-size: 16px;
  letter-spacing: 0.2px;
  font-weight: 500;
  color: $brown;
}
.nrw-slider__text-block{
  padding-left: 40px;
}

#liveDiv {
  // margin: 5px 5px;
  // border-radius: 100px !important;
  box-shadow: 2px 3px 5px $brown;
}

.live-chart-action {
  padding: 0 4.5px;
  margin-bottom: 5px;
  md-button {
    background-color: $brown;
    border-radius: 2px;
    // color: $darkgreen;
    float: right;
    padding: 0 3px;
  }
  img {
    width: 24px;
    height: auto;
  }
}

.live-title {
  padding: 3px 15px;
  font-size: 12px;
  color: $white;
  font-weight: bold;
  text-align: left;
  width: 100%;
  height: 25px;
  color: $white;
  background-color: $darkgreen;
  ul {
    display: inline-block;
    float: right;
    height: 0;
    margin-right: -20px;
    padding-left: 5px;
  }
  ul.live-list li {
    display: inline-block;
    padding-right: 10px;
    list-style: none;
    text-align: left;
    font-size: 10px;
    color: black;
    a {
      color: $white;
      font-size: small;
    }
    a:hover {
      text-decoration: underline;
    }
    img {
      width: 30px;
      height: auto;
      padding-right: 6px;
      margin-top: -4px;
    }
  }
}

.live-title:first-child {
  border-top-left-radius: 2px;
}

.live-view {
  float: right;
  margin-left: 1vw;
}

.live-reading {
  display: inline-block;
  margin: 0 0 0 15px;
  min-width: 151.2px;
  max-width: 151.2px;
  height: 30px;
  padding-top: 2px;
  color: $silver;
  span.title {
    font-size: 16px;
    padding: 1px;
    color: $darkgreen;
    font-weight: 500;
  }
  .odometer-theme-car {
    // margin-top: -5px;
  }
}

.live-title__all {
  // padding: 3px 15px;
  font-size: 12px;
  color: $white;
  font-weight: bold;
  text-align: left;
  width: 100%;
  height: 25px;
  color: $white;
  background-color: $darkgreen;
  ul {
    display: inline-block;
    float: right;
    height: 0;
    padding-left: 5px;
  }
  ul.live-list li {
    display: inline-block;
    padding-right: 10px;
    list-style: none;
    text-align: left;
    font-size: 10px;
    color: black;
    a {
      color: $white;
      font-size: small;
    }
    a:hover {
      text-decoration: underline;
    }
    img {
      width: 30px;
      height: auto;
      padding-right: 6px;
      margin-top: -4px;
    }
  }
}

.live-reading__title {
  display: inline-block;
  margin: 0 0 0 16px;
  min-width: 151.2px;
  max-width: 151.2px;
  height: 30px;
  color: $silver;
  span.title {
    font-size: 14px;
    color: $white;
    font-weight: 500;
  }
  .odometer-theme-car {
    // margin-top: -5px;
  }
}

.live-close {
  position: absolute;
  width: auto;
  height: auto;
  top: 100px;
  right: 32.8vw;
  font-size: 12px;
  background-color: $darkgreen;
  padding: 4px 8px;
  color: $white;
  text-transform: capitalize;
  text-align: right;
  z-index: 0;
  border-radius: 3px;
  font-weight: 500;
}

.live-container {
  position: absolute;
  z-index: 0;
  width: fit-content;
  top: 60px;
  right: 0;
  border-radius: 5px;
  background-color: $silver;
  .live-asset__container {
    max-height: 55vh;
    overflow-x: hidden;
    overflow-y: scroll;
  }
  .update-bar {
    text-align: center;
    color: $white;
    background-color: $green;
    width: 100%;
    height: 2vh;
    margin: 0;
    padding: 0;
    margin-top: -10px;
    overflow: hidden;
    h4 {
      margin-top: 5px;
      vertical-align: middle;
      font-size: 14px;
    }
  }

  .live-panel-toolbar {
    .live-panel-nav {
      border-bottom: solid 1px $green;
    }
    min-height: fit-content !important;
    background-image: linear-gradient(
      to right bottom,
      #c0c0c0,
      #9b9b9e,
      #77787e,
      #53585f,
      #313941
    );
    .live-panel-history {
      margin: 4px;
      width: 30px;
      height: 30px;
      min-height: 30px;
      padding: 0;
    }
    .live-panel-close {
      margin: 4px;
      width: 30px;
      height: 30px;
      min-height: 30px;
      padding: 0;
      &:hover {
        background-color: $darkred !important;
      }
    }
    .live-panel-title {
      font-weight: 500;
      color: white;
      text-shadow: 1px 1px $green;
    }
  }
}

.md-fab-action-item:hover,
.md-fab-action-item md-button:hover {
  background-color: $silver !important;
}

.md-fab-action-item {
  height: 90px;
}

.md-fab-toolbar-wrapper {
  height: 90px !important;
}

.md-button.md-fab {
  width: 28px;
  height: 28px;
}

.radar-button {
  position: absolute;
  bottom: calc(45px + 120px);
  right: 0.5vw;
  width: 167px;
  margin: 0;
  color: $white !important;
  background-color: $green !important;
  border-radius: 2px;
  z-index: 0;
  &:hover {
    background-color: $darkgreen !important;
  }
}

.search-button {
  position: absolute;
  bottom: calc(42px + 165px);
  right: 0.5vw;
  width: 167px;
  margin: 0;
  color: $white !important;
  background-color: $darkgreen !important;
  border-radius: 2px;
  z-index: 0;
  &:hover {
    background-color: $green !important;
  }
}

.ava-button {
  position: absolute;
  bottom: calc(44px + 165px);
  right: 0.5vw;
  width: 167px;
  margin: 0;
  color: $white;
  background-color: $darkgreen !important;
  border-radius: 2px;
  z-index: 0;
  img {
    padding: 4px;
    width: 80px;
  }
}
.trigger-container-live {
  position: absolute;
  bottom: calc(45px + 18px);
  //left: 10.5vw;
  right: 0.5vw;
  height: 96px;
  width: 167px;
  background-color: $silver;
  border-radius: 4px;
  z-index: 0;
  text-align: center;
  .live-date {
    color: $brown;
    font-weight: 400;
    .live-value {
      font-weight: 700;
    }
  }
  span {
    color: $darkgreen;
    font-size: small;
    // margin-top: 5px;
    // border-bottom: 2px solid $silver;
    /* position: absolute; */
    /* right: 0.5vw; */
    /* bottom: 6.2vh; */
    display: block;
    background-color: $brown;
    color: white;
    border-radius: 2px;
    border-radius: 2px;
    font-weight: bold;
    text-transform: capitalize;
    &.subtitle {
      font-size: small;
      text-align: center;
      display: block;
      span {
        display: inline-block;
      }
    }
  }
}

.close-hm {
  position: absolute;
  padding: 0 !important;
  bottom: 4vh;
  color: #f3f4f4;
  background-color: $secondarygreen !important;
  color: $white !important;
  // padding: 3px;
  /* right: 0vw; */
  text-shadow: 1px 1px 1px black;
  border-left: 1px solid black;
  display: inline-block;
  margin-top: -26px;
  float: right;
  @media screen and (min-width: $break-large) {
    bottom: 0vh;
  }
  @media screen and (min-width: $break-small) {
    bottom: 0vh;
  }
  @media screen and (min-width: $break-xlarge) {
    bottom: 0vh;
  }
  button {
    // display: block;
    // margin: 9px -1px;
    // font-size: 16px;
    padding: 0.3vh 10px;
    color: white;
    background: none;
    border: none;
    &:focus {
      outline: none;
    }
  }

  .ewp-info {
    color: white;

    &:hover {
      color: $green;
    }
  }
  .ewp-close {
    color: white;
    &:hover {
      color: red;
    }
  }
}

.ari-marker {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  color: black;
  border: 2px solid white;
  text-align: center;
  line-height: 18px;
  font-size: 10px;
  font-weight: 500;
}

.ari-chart {
  position: absolute;
  min-width: 400px;
  min-height: 280px;
}

.ari-list {
  position: absolute;
  bottom: 58px;
  left: calc(13vw);
  z-index: 100;
  width: 70vw;
  .left-edge {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }
  .right-edge {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }
  .ari-legend {
    li {
      text-align: center;
    }
  }
  .closeAri {
    position: absolute;
    left: -2vw;
    color: $black;
    text-align: center;
    // bottom: 0;
    p {
      background-color: $darkgreen;
      color: $white;
      border-radius: 3px;
      line-height: 22px;
      height: 25px;
    }
    p:hover {
      background-color: $green;
      color: $white;
      border-radius: 3px;
      line-height: 22px;
      height: 25px;
    }
  }
  div {
    margin: 0;
    background-color: none;
    color: $white;
    list-style: none;
    min-height: 6px;
    &.green-1 {
      color: black;
    }
    &.green-2 {
      color: black;
    }
    &.green-3 {
      color: black;
    }
    &.yellow-1 {
      color: black;
    }
    &.yellow-2 {
      color: black;
    }
    &.yellow-3 {
      color: black;
    }
    ul {
      position: absolute;
      bottom: 18px;
      max-height: 80vh;
      overflow-y: scroll;
      li {
        // margin-bottom: -158px !important;
      }
    }
    img {
      // position: absolute;
      // border-radius: 100px;
      // background-color: $white;
      float: right;
      width: 32px;
      padding: 3px;
      margin-top: -2px;
      height: auto;
      display: inline-block;
    }
    span {
      text-align: right;
      font-size: large;
      display: block;
    }
    &.ari-list-counter {
      background-color: $darkgreen;
      display: block;
      border-bottom-left-radius: 80%;
      border-bottom-right-radius: 80%;
      width: 24px;
      // padding: 0 6px 0 0;
      text-align: center;
      span {
        font-size: 12px;
      }
    }
    .ari-legend ul,
    ul {
      min-height: 10px;
      background-color: $darkgreen;
      li {
        color: $white;
        display: block;
        padding: 5px;
        &.green-1 {
          color: black;
        }
        &.green-2 {
          color: black;
        }
        &.green-3 {
          color: black;
        }
        &.yellow-1 {
          color: black;
        }
        &.yellow-2 {
          color: black;
        }
        &.yellow-3 {
          color: black;
        }
      }
    }
  }
}

// .md-panel-is-showing {
.custom-tooltip {
  width: 30%;
  max-width: 100%;
  wrap // display: block !important;
    p {
    // display: block !important;
    max-width: 1vw !important;
  }
}

// .md-panel-is-showing {
.custom-tooltip {
  width: 23%;
  max-width: 100%;
  wrap // display: block !important;
    p {
    // display: block !important;
    max-width: 1vw !important;
  }
}

// }
.ari-controls {
  background-color: $darkgreen;
  display: block;
  width: 165px;
  color: $white;
  padding: 0;
  text-align: center;
  position: absolute;
  bottom: calc(45px + 58.2px);
  left: 0.5vw;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
  border-radius: 2px;
  md-icon {
    font-size: 21.5px;
    border-radius: 90%;
    background-color: #f3f4f4;
    padding: 0;
    margin: 5px 4px 0 -15px;
    color: $darkgreen;
    float: right;
    display: inline-block;
    width: 20%;
    height: 0px;
    max-height: 0px;
    max-width: 2px;
    border: 2px solid $darkgreen;
  }
  span {
    /* margin: 0 50px; */
    font-size: 18px;
    font-weight: bolder;
    text-shadow: 1px 0px 7px black;
    font-size: 24px;
    padding: 0;
    padding-left: 20px;
    display: inline-block;
    width: 80%;
    text-align: center;
    margin: 0;
    letter-spacing: 2px;
    float: left;
    color: $blue;
  }
}

.close-ewp {
  /* position: absolute; */
  /* bottom: 4vh; */
  color: #f3f4f4;
  background-color: $silver;
  padding: 12px;
  /* right: 0vw; */
  text-shadow: 1px 1px 1px black;
  border-left: 1px solid black;
  display: inline-block;
  margin-top: -9px;
}

// .map-bg {
//     background: url(images/map-bg.jpg) no-repeat;
//     background-position: 0px 0px;
//     background-size: auto;
//     width: 100%;
//     height: 440px; /*adjust to the height of your image*/
//     position: relative;
// }
.marker {
  // width: auto;
  // height: auto;
  // position: absolute;
  // top: 0px;
  /*positions our marker*/
  // left: 0px;
  /*positions our marker*/
  // display: block;
}

.pin {
  width: 24px;
  height: 24px;
  position: absolute;
  // top: -21px;
  // left: -17px;
  // background: rgba(255, 0, 0, 1);
  border-radius: 50%;
  z-index: 1000;
  img {
    width: 24px;
    height: 24px;
    position: absolute;
    top: 8px;
    left: 8px;
  }
}

.pin-effect {
  width: 34px;
  height: 34px;
  position: absolute;
  top: -5px;
  left: -5px;
  display: block;
  background: rgba(255, 0, 0, 0.6);
  border-radius: 50%;
  opacity: 0;
  animation: pulsate 2400ms ease-out infinite;
}

.pin-effect-yellow {
  width: 34px;
  height: 34px;
  position: absolute;
  top: -18px;
  left: -19px;
  display: block;
  background: #f9682a;
  border-radius: 50%;
  opacity: 0;
  animation: pulsate 2200ms ease-out infinite;
}

.pin-effect-sm {
  width: 34px;
  height: 34px;
  position: absolute;
  top: -29px;
  left: -29px;
  display: block;
  background: red;
  border-radius: 50%;
  opacity: 0;
  animation: pulsate 2200ms ease-out infinite;
}
.pin-effect-red {
  width: 15px;
  height: 15px;
  position: absolute;
  top: -8px;
  left: -6px;
  display: block;
  background: red;
  border-radius: 50%;
  opacity: 0;
  animation: pulsate 2000ms ease-out infinite;
}

.pin-effect-black {
  width: 15px;
  height: 15px;
  position: absolute;
  top: -8px;
  left: -6px;
  display: block;
  background: black;
  border-radius: 50%;
  opacity: 0;
  animation: pulsate 2000ms ease-out infinite;
}
.pin-effect-red-legend {
  float: left;
  width: 8px;
  height: 8px;
  margin: 6px 6px 0 20px;
  display: block;
  background: red;
  border-radius: 50%;
  opacity: 0;
  animation: pulsate 2000ms ease-out infinite;
}

.pin-effect-black-legend {
  float: left;
  width: 8px;
  height: 8px;
  margin: 6px 6px 0 20px;
  display: block;
  background: black;
  border-radius: 50%;
  opacity: 0;
  animation: pulsate 2000ms ease-out infinite;
}

.pin-effect-green {
  width: 15px;
  height: 15px;
  position: absolute;
  top: -8px;
  left: -6px;
  display: block;
  background: green;
  border-radius: 50%;
  opacity: 0;
  // animation: pulsate 1500ms ease-out 5;
}

.pin-effect-blue {
  width: 52px;
  height: 52px;
  position: absolute;
  top: -26px;
  left: -24px;
  display: block;
  background: blue;
  border-radius: 50%;
  opacity: 0;
  animation: pulsate 1500ms ease-out infinite;
}

@keyframes pulsate {
  0% {
    transform: scale(0.1);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: scale(1.2);
    opacity: 0;
  }
}

md-slider.md-default-theme .md-thumb-text,
md-slider .md-thumb-text {
  color: $green;
}

.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: 0 0 4em;
  height: 300px !important;
  z-index: 100000 !important;
}
.loader-text {
  position: absolute;
  top: 45%;
  left: 36%;
  font-weight: 500;
  font-size: 16px;
  margin: 0 0 4em;
  height: 300px;
  color: $red;
}

.loader--5 {
  margin: 0 0 4em;
  height: 300px;
  width: 40%;
  text-align: center;
  padding: 1em;
  margin: 0 auto 1em;
  display: inline-block;
  vertical-align: top;
  /*
  Set the color of the icon
*/
  svg path,
  svg rect {
    fill: #393c42;
  }
}

.arrow_box .arrow-down {
  width: 13px;
  height: auto;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  padding: 0;
  margin-left: 47.8%;
  margin-bottom: -20px;
  // margin-left: 235px;
  border-top: 20px solid $brown;
  display: none;
}

.arrow_box,
.arrow-box {
  position: relative;
  background: $silver;
  // border: 3px solid $brown;
  border-radius: 2px;
  z-index: 9999;
  margin-top: -298px;
  margin-left: -250px;
  width: fit-content;
}

.arrow_box:after,
.arrow_box:before {
  top: 100%;
  left: 50%;
  border: 2px solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.arrow_box:after {
  border-color: rgba(192, 183, 149, 0);
  border-top-color: $darkgreen;
  border-width: 10px;
  margin-left: -10px;
}

.arrow_box:before {
  border-color: rgba(192, 183, 149, 0);
  border-top-color: $green;
  border-width: 13px;
  margin-left: -13px;
}

.arrow_box_smad .arrow-down-smad {
  width: 13px;
  height: auto;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  padding: 0;
  margin-left: 47.8%;
  margin-bottom: -20px;
  // margin-left: 235px;
  border-top: 20px solid $brown;
  display: none;
}
.arrow_box_smad,
.arrow-box-smad {
  position: relative;
  background: $silver;
  // border: 3px solid $brown;
  border-radius: 2px;
  z-index: 9999;
  margin-top: -298px;
  margin-left: -250px;
  width: fit-content;
}

.arrow_box_smad:after,
.arrow_box_smad:before {
  top: -7%;
  left: 50%;
  border: 2px solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.arrow_box_smad:after {
  border-color: rgba(192, 183, 149, 0);
  border-bottom-color: $darkgreen;
  border-width: 10px;
  margin-left: -10px;
}

.arrow_box_smad:before {
  border-color: rgba(192, 183, 149, 0);
  border-bottom-color: $green;
  border-width: 13px;
  margin-left: -13px;
}

.btn {
  position: fixed;
  z-index: 201;
}

.hydraulic-container {
  position: absolute;
  border-radius: 4px;
  z-index: 0;
  top: 55px;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  md-radio-button {
    display: inline-block;
    margin: 7px;
  }
  h3 {
    margin: 0;
    margin-bottom: 5px;
    text-align: center;
    background-color: grey;
    color: $white;
    width: 30vw;
    padding: 0;
    margin: 0;
    display: inline-block;
    &.cold {
      background-color: grey;
    }
    &.hot {
      background-color: $darkgreen;
    }
    span {
      color: $white;
      display: inline-block;
      padding: 0px 0px 10px 2px;
      vertical-align: middle;
    }
  }

  .livemd .pressuremd,
  .livemd .demandmd {
    md-checkbox {
      padding-top: 0px;
      margin-bottom: 3px;
    }

    border: 1px solid $white;
    margin: 0;
    padding: 0 5px;
    text-align: center;
    background-color: grey;
    color: $white;
    width: fit-content;
    // max-height: 3vmin;
    margin: 0;
    display: inline-block;
    border-radius: 4px;
    .md-icon {
      margin-top: 1px;
    }
    &.cold {
      background-color: grey;
    }
    &.hot {
      background-color: $darkgreen;
    }
    margin-top: 2px;
    span {
      color: $white;
      font-size: 16px;
      // line-height: 16px;
      display: inline-block;
      // margin-top: 2px;
      // padding: 0px 0px 2px 2px;
      vertical-align: middle;
    }
  }
  .hydraulicmd {
    // display: flex !important;
  }
  .demandmd {
    text-align: center;
    width: 30vw;
    float: left;
  }
  .pressuremd {
    text-align: center;
    width: 30vw;
    float: left;
  }
  span {
    color: $darkgreen;
    font-size: small;
    // margin-top: 5px;
    // border-bottom: 2px solid $brown;
    /* position: absolute; */
    /* right: 0.5vw; */
    /* bottom: 6.2vh; */
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    display: block;
    //background-color: $brown;
    color: $darkgreen;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
    font-weight: 500;
  }
}

.hm-sec-container {
  position: absolute;
  border-radius: 4px;
  z-index: 0;
  top: 100px;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  md-radio-button {
    display: inline-block;
    margin: 7px;
    &:focus {
      outline: none;
    }
  }
  h3 {
    margin: 0;
    margin-bottom: 5px;
    text-align: center;
    background-color: grey;
    color: $white;
    width: 30vw;
    padding: 0;
    margin: 0;
    display: inline-block;
    &.cold {
      background-color: grey;
    }
    &.hot {
      background-color: $darkgreen;
    }
    span {
      color: $white;
      display: inline-block;
      padding: 0px 0px 10px 2px;
      vertical-align: middle;
    }
  }

  .livemd .pressuremd,
  .livemd .demandmd {
    md-checkbox {
      padding-top: 0px;
      margin-bottom: 3px;
    }

    border: 1px solid $white;
    margin: 0;
    padding: 0 5px;
    text-align: center;
    background-color: grey;
    color: $white;
    width: fit-content;
    // max-height: 3vmin;
    margin: 0;
    display: inline-block;
    border-radius: 4px;
    .md-icon {
      margin-top: 1px;
    }
    &.cold {
      background-color: grey;
    }
    &.hot {
      background-color: $darkgreen;
    }
    margin-top: 2px;
    span {
      color: $white;
      font-size: 16px;
      // line-height: 16px;
      display: inline-block;
      // margin-top: 2px;
      // padding: 0px 0px 2px 2px;
      vertical-align: middle;
    }
  }
  .hydraulicmd {
    // display: flex !important;
  }
  .demandmd {
    text-align: center;
    width: 30vw;
    float: left;
    background-color: $white;
  }
  .pressuremd {
    text-align: center;
    width: 30vw;
    float: left;
    background-color: $white;
  }
  span {
    color: $darkgreen;
    font-size: small;
    // margin-top: 5px;
    // border-bottom: 2px solid $brown;
    /* position: absolute; */
    /* right: 0.5vw; */
    /* bottom: 6.2vh; */
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    display: block;
    //background-color: $brown;
    color: $darkgreen;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
    font-weight: 500;
  }
}

md-radio-button.md-checked .md-off {
  border-color: $green;
}

md-radio-button .md-on {
  background-color: $darkgreen;
}

.ari-close {
  background-color: $brown;
  border-radius: 4px;
}

h5 {
  color: $brown;
}

.ari-box {
  position: absolute;
}

.g-gtitle {
  text-transform: capitalize !important;
}

.map-icon {
  width: 24px !important;
  max-width: 24px !important;
  height: auto;
}

.leaflet-div-icon {
  background: none;
  border: none;
}

.info-bar {
  background-color: $darkblue !important;
  min-height: 45px !important;
}

.info-h1 {
  margin: 0;
  text-align: center;
  font-size: 24px;
  padding-top: 12px;
}

.info-data {
  font-size: 13px;
  color: #eef0f3;
  margin: 5px;
  font-weight: 600;
  letter-spacing: 0.2px;
  line-height: 20px;
}

.info-toolbar {
  min-height: 0;
  display: inline-grid !important;
}

.info-content {
  background-color: $brown !important;
  .con-heading {
    text-align: center;
  }
  .con-img {
    text-align: center;
  }
  // background-image: linear-gradient(to top, #119147, #007d65, #00676e, #174f5f, #313941);
}

.info-heading {
  margin-top: 0;
  text-align: center;
  text-decoration: underline;
  font-size: large;
  font-weight: 700;
}

.close-info {
  background-image: linear-gradient(
    to left,
    #e51313,
    #e52e2a,
    #e3403c,
    #e04f4d,
    #dc5d5d
  );
  width: 95%;
  color: $white;
  &:hover {
    background-color: $red !important;
    color: $white !important;
  }
}

.text-para {
  text-shadow: 0.2px 0 0.5px $black;
}

.popup-dialog {
  width: 85% !important;
  height: 90% !important;
  .popup-dialog-headar {
    height: 50px;
    background-color: $secondarygreen;
    .header-text {
      font-size: 20px;
      font-weight: 500;
    }

    .header-text__form {
      font-size: 20px;
      padding-left: 20px;
      font-weight: 500;
    }
    .popup-dialog-icon {
      margin: 5px;
      max-height: 40px;
      max-width: 40px;
    }
  }
  .map-radar {
    height: 100%;
  }
  .popup-dialog-close {
    background-color: $orange;
    color: $white;
    font-weight: 500;
    font-size: 1em;
    float: right;
    &:hover {
      background-color: $darkred !important;
    }
  }
  .md-nav-bar {
    // background-color: $darkgreen !important;
    overflow-x: scroll;
    height: fit-content !important;
    .md-button.md-accent {
      color: $green !important;
    }
    md-nav-ink-bar {
      background-color: $green;
      color: $green;
    }
  }
}

.flushing-adv {
  background-color: $darkgreen !important;
  display: block;
  width: 165px;
  color: $white !important;
  padding: 0;
  // padding-right: 4px;
  text-align: center;
  position: absolute;
  bottom: calc(45px + 105px);
  margin: 0 !important;
  max-height: 6vh;
  left: 0.5vw;
  &:hover {
    background-color: $green !important;
  }
}

.ewp-adv {
  background-color: $darkgreen !important;
  display: block;
  width: 165px;
  color: $white !important;
  padding: 0;
  // padding-right: 4px;
  text-align: center;
  position: absolute;
  bottom: calc(45px + 10px);
  margin: 0 !important;
  max-height: 6vh;
  left: 0.5vw;
  // span{
  //     font-size: 11px;
  // }

  &:hover {
    background-color: $green !important;
  }
}
.flushing-adv-content {
  min-width: 400px;
  margin-top: 45px;
  #vertical-container {
    min-height: 85vh !important;
  }
  // padding-right: 10px;
  .adv-table {
    table.md-table thead.md-head > tr.md-row {
      height: 20px !important;
      text-transform: capitalize !important;
    }
    table.md-table:not(.md-row-select)
      td.md-cell:nth-child(n + 2):nth-last-child(n + 2),
    table.md-table:not(.md-row-select)
      th.md-column:nth-child(n + 2):nth-last-child(n + 2) {
      padding: 5px;
    }
    table.md-table thead.md-head > tr.md-row th {
      height: 8px !important;
      text-transform: capitalize !important;
      // background-color: $darkblue !important;
      padding: 0 !important;
    }
    table.md-table:not(.md-row-select)
      td.md-cell:nth-child(n + 2):nth-last-child(n + 2) {
      padding: 5px !important;
    }
    table.md-table th.md-column {
      // color: $white !important;
      font-weight: 600 !important;
      text-align: center !important;
    }
    table.md-table th.md-column {
      color: $black;
      font-size: 13px;
      text-transform: uppercase;
    }

    .select-status {
      color: red;
    }
    // td.md-cell:nth-child(even),
    // th.md-column:nth-child(even) {
    //     // background-color: $black !important;
    //     color: $black;
    //     text-align: center;
    // }
    // td.md-cell:nth-child(odd),
    // th.md-column:nth-child(odd) {
    //     // background-color: $black !important;
    //     // color: $white !important;
    // }
    .md-row .md-column,
    .md-row .md-cell {
      text-align: center !important;
      padding: 5px !important; // padding-left: 1%;
      font-size: calc(0.27rem + 1vmin);
      font-weight: 700;
    }
    table.md-table tbody.md-body > tr.md-row,
    table.md-table tfoot.md-foot > tr.md-row {
      height: 28px !important;
      text-transform: capitalize;
      text-align: left;
    }
    table.md-table th.md-column md-icon {
      font-size: 12px !important;
      min-width: 15px;
    }
    table.md-table tbody.md-body > tr.md-row:nth-child(odd) > td {
      background-color: #e6e5e5;
    }
  }
  .dead-end-filters {
    height: 40px;
    background-color: $green;
    // padding-top:4px;
    color: white;
    .dead-end-filters-text {
      font-size: 16px;
      font-weight: 500;
      .length-adv {
        font-size: 10px;
      }
    }
    md-checkbox {
      margin-bottom: 0;
    }
    md-checkbox:not(.md-checked) .md-icon {
      border-color: $white;
    }

    .color-0 {
      md-checkbox.md-checked .md-icon {
        background-color: #000000;
      }
      md-checkbox.md-checked .md-ink-ripple {
        color: $white;
      }
    }
    .color-1 {
      md-checkbox.md-checked .md-icon {
        background-color: #ffa500;
      }
      md-checkbox.md-checked .md-ink-ripple {
        color: $white;
      }
    }
    .color-2 {
      md-checkbox.md-checked .md-icon {
        background-color: #ff0000;
      }
      md-checkbox.md-checked .md-ink-ripple {
        color: $white;
      }
    }
    .hrt-0 {
      md-checkbox.md-checked .md-icon {
        background-color: #2c85ff;
      }
      md-checkbox.md-checked .md-ink-ripple {
        color: $white;
      }
    }
    .hrt-1 {
      md-checkbox.md-checked .md-icon {
        background-color: #000000;
      }
      md-checkbox.md-checked .md-ink-ripple {
        color: $white;
      }
    }
    .hrt-2 {
      md-checkbox.md-checked .md-icon {
        background-color: #ffa500;
      }
      md-checkbox.md-checked .md-ink-ripple {
        color: $white;
      }
    }
    .hrt-3 {
      md-checkbox.md-checked .md-icon {
        background-color: #ff0000;
      }
      md-checkbox.md-checked .md-ink-ripple {
        color: $white;
      }
    }
  }
  .adv-options {
    padding: 10px 0 10px 5px;
    font-weight: 700;
    background-color: $green;
    color: $white;
    md-radio-button.md-checked .md-off {
      border-color: $white;
    }
  }
  .md-nav-item:first-of-type {
    margin: 0;
  }
  .adv-options-bar {
    .md-nav-bar {
      height: fit-content;
      background-color: $darkgreen;
    }
    .md-button._md-nav-button {
      margin: 0;
      padding: 0;
    }
    .md-nav-item {
      padding: 5px 10px;
    }
    .md-button.md-accent {
      color: $white;
    }
    md-nav-bar .md-button._md-nav-button.md-unselected {
      color: grey;
    }
    .md-nav-bar md-nav-ink-bar {
      color: $white;
      background-color: $white;
    }
  }
}

.smart-meter-content {
  min-width: 400px;
  margin-top: 45px;
  .user-insights-bar {
    height: fit-content;
    background-color: #313941;
    color: white;
    font-weight: 500;
    // text-transform: uppercase;
    font-size: 18px;
    padding: 4px;
  }
  .no-anamaly-text {
    text-align: center;
    font-weight: 500;
    padding-top: 10px;
    color: red;
  }
  .user-meter-section {
    md-input-container {
      margin: 0;
    }
    md-input-container .md-errors-spacer {
      min-width: unset;
    }
    .md-input {
      background-color: #ffffff;
      color: black !important;
      font-weight: 500;
    }
  }
  .percentile-filters {
    height: fit-content;
    background-color: $green;
    // padding-top:4px;
    color: white;
    .percentile-filters-text {
      font-size: 16px;
      font-weight: 500;
      padding-left: 12px;
      .length-adv {
        font-size: 10px;
      }
    }
    .user-insight-slider {
      padding-left: 12px;
      .rzslider {
        //   margin:0;
        //  margin: 4px;
        margin-top: 37px;
      }
      .rzslider .rz-bar-wrapper {
        padding: 0;
      }
      .rzslider .rz-pointer {
        width: 24px;
        height: 24px;
        border-radius: 4px;
        top: -20px;
        background-color: $darkgreen;
        &:focus {
          outline: none;
        }
      }
      .rzslider .rz-bar {
        height: 8px;
        top: 5px;
      }
      .rzslider .rz-pointer:after {
        position: absolute;
        top: 9px;
        left: 8px;
        width: 8px;
        height: 8px;
        background: $green-shade;
        border-radius: 4px;
        content: "";
      }
      .rzslider .rz-selection {
        background: $darkgreen;
      }
      .rzslider .rz-bubble {
        bottom: unset;
        color: white;
        font-weight: 400;
        font-size: 12px;
        padding-top: 3px;
      }
    }

    .action-icons {
      padding-left: 12px;
      .md-button.md-fab {
        margin-top: 0;
      }
      .md-button.md-fab.md-mini {
        margin: 0;
        width: 23px;
        height: 23px;
        min-height: 23px;
        padding: 0;
      }
      .apply-icon {
        background-color: $green-shade !important;
        md-icon {
          color: $darkgreen;
          font-size: 18px;
          line-height: 1.3;
        }
      }
      .download-icon {
        background-color: $red !important;
        margin-left: 8px !important;
        md-icon {
          color: white;
          font-size: 18px;
          line-height: 1.3;
        }
      }
    }

    // .slider-apply-btn{
    //   // margin-left:12px;
    //   min-width: unset;
    //   background-color: $green-shade;
    //   color:$darkgreen;
    // }
  }
  md-checkbox {
    margin-bottom: 0;
  }
  md-checkbox:not(.md-checked) .md-icon {
    border-color: $white;
  }
  md-checkbox.md-checked .md-icon {
    background-color: #ffa500;
  }
  md-checkbox.md-checked .md-ink-ripple {
    color: $white;
  }
  .adv-table {
    table.md-table thead.md-head > tr.md-row {
      height: 20px !important;
      text-transform: capitalize !important;
    }
    table.md-table:not(.md-row-select)
      td.md-cell:nth-child(n + 2):nth-last-child(n + 2),
    table.md-table:not(.md-row-select)
      th.md-column:nth-child(n + 2):nth-last-child(n + 2) {
      padding: 5px;
    }
    table.md-table thead.md-head > tr.md-row th {
      height: 8px !important;
      text-transform: capitalize !important;
      // background-color: $darkblue !important;
      padding: 0 !important;
    }
    table.md-table:not(.md-row-select)
      td.md-cell:nth-child(n + 2):nth-last-child(n + 2) {
      padding: 5px !important;
    }
    table.md-table th.md-column {
      // color: $white !important;
      font-weight: 600 !important;
      text-align: center !important;
    }
    table.md-table th.md-column {
      color: $black;
      font-size: 13px;
      text-transform: uppercase;
    }

    .select-status {
      color: red;
    }
    .md-row .md-column,
    .md-row .md-cell {
      text-align: center !important;
      padding: 5px !important; // padding-left: 1%;
      font-size: calc(0.27rem + 1vmin);
      font-weight: 700;
    }
    table.md-table tbody.md-body > tr.md-row,
    table.md-table tfoot.md-foot > tr.md-row {
      height: 28px !important;
      text-transform: capitalize;
      text-align: left;
    }
    table.md-table th.md-column md-icon {
      font-size: 12px !important;
      min-width: 15px;
    }
    table.md-table tbody.md-body > tr.md-row:nth-child(odd) > td {
      // background-color: #e6e5e5;
    }
  }
  #vertical-container {
    min-height: 85vh !important;
  }
  #smad-vertical-container {
    min-height: 45vh !important;
  }

  // .md-virtual-repeat-container {
  //     min-height: calc(100vh - 75px);
  // }
}

.leaflet-container .leaflet-control-attribution {
  display: none !important;
}

.vrepeatContainer #vertical-container {
  // min-height: 85vh !important;
  // width: 390px;
}

.vrepeatContainer .repeated-item-vertical {
  // border-bottom: 1px solid #ddd;
  // box-sizing: border-box;
  height: 40px;
  &:focus {
    // background-color: $green;
    outline: none;
  }

  // padding-top: 10px;
}

.vrepeatContainer md-content {
  // margin: 16px;
}

.vrepeatContainer md-virtual-repeat-container {
  // border: solid 1px grey;
}

.select-pop-up-green {
  table.md-table tbody.md-body > tr.md-row:nth-child(1) > td {
    background-color: black !important;
    color: $white;
  }
}
.select-pop-up-black {
  table.md-table tbody.md-body > tr.md-row:nth-child(1) > td {
    background-color: black !important;
    color: $white;
  }
}
.select-pop-up-blue {
  table.md-table tbody.md-body > tr.md-row:nth-child(1) > td {
    background-color: #2c85ff !important;
    color: $white;
  }
}
.select-pop-up-red {
  table.md-table tbody.md-body > tr.md-row:nth-child(1) > td {
    background-color: #ff0000 !important;
    color: $white;
  }
}

.select-pop-up-orange {
  table.md-table tbody.md-body > tr.md-row:nth-child(1) > td {
    background-color: $secondary !important;
    color: $white;
  }
}

.un-select-pop-up {
  table.md-table tbody.md-body > tr.md-row:nth-child(1) > td {
    background-color: #e6e5e5;
  }
}
.close-flushing-adv {
  position: absolute;
  left: 400px;
  z-index: 9;
  top: 45px;
  background-color: $white;
}
.info-flushing-adv {
  position: absolute;
  left: 400px;
  top: 75px;
  z-index: 9;
  background-color: $white;
}

.download-flushing-adv {
  position: absolute;
  left: 400px;
  top: 105px;
  z-index: 9;
  background-color: $white;
}

.info-smad {
  position: absolute;
  left: 400px;
  top: 75px;
  z-index: 9;
  background-color: $white;
}

.compliance-container {
  position: absolute;
  bottom: 19px;
  right: 10px;
  width: 130px;
  background-color: $silver;
  border-radius: 40px;
  z-index: 0;
  text-align: center;
  .compliance-btn {
    // background-color: $green;
    border-radius: 50%;
    border: none;
    color: #ffffff;
    font-family: Arial;
    font-size: 9px;
    font-weight: 600;
    letter-spacing: 0.2px;
    text-transform: uppercase;
    text-align: center;
    text-decoration: none;
    width: 24px;
    height: 24px;
    line-height: 24px;
    min-width: unset;
    min-height: unset;
    margin: 0;
    padding: 0;

    .md-button:not([disabled]):hover {
      background-color: none;
    }
    &:hover {
      color: #ffffff;
      text-decoration: none;
      background-color: transparent;
    }
  }
  .c-green {
    background-color: #028106;
    color: $white;
    &:hover {
      background-color: #028106;
    }
  }
  .c-red {
    background-color: #e3490e;
    color: $white;
    &:hover {
      background-color: #e3490e;
    }
  }
  .c-gray {
    background-color: gray;
    color: $white;
    cursor: not-allowed;
    &:hover {
      background-color: gray;
      text-decoration: none;
    }
  }
  .c-yellow {
    background-color: yellow;
    color: black;
    &:hover {
      background-color: yellow;
    }
  }
  .compliance-header {
    background-color: $darkgreen;
    font-size: 14px;
    font-weight: 500;
    color: $white;
    border-radius: 2px 2px 0 0;
    text-transform: uppercase;
    padding: 2px 0;
    letter-spacing: 0.1px;
  }
  .compliance-cats {
    background-color: $darkgreen;
    font-size: 14px;
    font-weight: 500;
    color: $white;
  }
  .compliance-btns {
    padding: 4px 0;
  }
}

.zoom-reset {
  top: calc(40vh + 26px);
  position: absolute;
  left: 10px;
  border: 2px solid $silver;
  z-index: 0;
  box-shadow: none;
  border-bottom-left-radius: unset;
  border-bottom-right-radius: unset;
  a {
    width: 30px !important;
    height: 30px !important;
    md-icon {
      color: black;
      line-height: 1.2;
    }
  }
}

.layer-reset {
  top: calc(40vh - 4px);
  position: absolute;
  left: 10px;
  border: 2px solid $silver;
  z-index: 0;
  box-shadow: none;
  border-bottom-left-radius: unset;
  border-bottom-right-radius: unset;

  a {
    width: 30px !important;
    height: 30px !important;
    md-icon {
      color: black;
      line-height: 1.1;
    }
  }
}

.layer_reset_expand {
  top: calc(40vh - 4px);
  position: absolute;
  left: 400px;
  border-bottom: 2px solid $silver;
}

.zoom_reset_expand {
  position: absolute;
  top: calc(40vh + 26px);
  left: 400px;
  border-bottom: 2px solid $silver;
}

@-webkit-keyframes dash {
  to {
    stroke-dashoffset: 0;
  }
}
@keyframes dash {
  to {
    stroke-dashoffset: 0;
  }
}

.dates {
  position: absolute;
  left: 45vw;
  z-index: 0;
  width: 100vw;
  top: 0vh;
  // margin-left: -250px;
  margin-top: -20px;
  &.ewp {
    font-weight: bold;
    top: 92.8vh;
    right: 0vw;
    margin-left: -24vw;
    @media screen and (min-width: $break-small) {
      margin-left: -24vw;
    }
  }
  ul li {
    &:hover,
    &:active {
      background-color: $darkgreen;
      color: $white;
    }
    border-right: 1px solid $darkgreen;
    text-align: center;
    list-style: none;
    float: left;
    padding: 0.5vh 3vw;
    background-color: $silver;
    color: $brown;
    &:focus {
      outline: none;
    }
  }
  li.active {
    background-color: $darkgreen !important;
    color: $white;
  }
}
.heatmap-slider {
  position: absolute;
  bottom: 5vh;
  width: 65vw;
  margin: 0 0 0 20vw;
  z-index: 1;
  border-radius: 2px;
  border: 1px solid $darkgreen;
  // color: white;
  background-color: $silver;
  // background-image: linear-gradient(to right bottom, #ffffff, #efefef, #dfdfdf, #cfcfcf, #c0c0c0);
  .md-track.md-track-fill {
    background-color: $green;
  }
  md-slider .md-track-container {
    height: 5px;
  }
  md-slider.md-default-theme .md-thumb:after,
  md-slider .md-thumb:after {
    border-color: $green;
    background-color: $green;
  }
  // md-slider .md-track-ticks{
  //     color: $white;
  // }
  .md-sign {
    background-color: $green;
    &:after {
      border-top-color: $green;
    }
  }
  .heat-map-close {
    margin: 4px 4px 4px 20px;

    &:hover {
      background-color: $darkred !important;
    }
  }
}

md-checkbox.md-checked .md-icon {
  background-color: $green;
}

.hyd-marker {
  width: 38px;
  height: 38px;
  background-color: rgb(213, 212, 212);
  border-radius: 50%;
  border: 1px $red solid;
  img {
    display: table;
    margin: 0 auto;
    width: 20px;
    height: 20px;
  }
  span {
    display: block;
    text-align: center;
  }
}

.smart-meter-marker {
  width: 38px;
  height: 38px;
  background-color: rgb(225, 225, 240);
  border-radius: 50%;
  border: 1px $darkblue solid;
  z-index: 0 !important;
  img {
    display: table;
    margin: 0 auto;
    width: 20px;
    height: 20px;
  }
  span {
    display: block;
    text-align: center;
    color: black;
  }
}

// .leaflet-div-icon {
//   z-index: 0 !important;
// }

.selected-row {
  background-color: #87ceeb !important;
  font-weight: bold;
}

.md-center {
  left: 50%;
  transform: translate3d(-50%, 0, 0);
}

.status-toast.md-default-theme .md-toast-content,
.status-toast .md-toast-content {
  background-color: $darkgreen;
  color: $white;
  font-weight: 500;
  font-size: 15px;
  letter-spacing: 0.1px;
  text-transform: initial;
  text-align: center;
  margin-top: 0;
  padding: 0;
  max-height: 20px !important;
  margin-bottom: 40px;
  md-icon {
    color: white;
    padding-left: 4px;
  }
}

#smartMeterChart {
  font-weight: 500 !important;
  font-size: 14px;
}

.userinsight-date-selector {
  position: absolute;
  top: 100px;
  left: 0;
  right: 0;

  .date-selector-block {
    background-color: #ff5722;
    border-radius: 4px;
    color: white;
    padding: 4px 20px;
    .md-datepicker-triangle-button.md-button.md-icon-button {
      background-color: transparent;
    }
    .md-button.md-icon-button md-icon {
      color: white;
    }
    .md-datepicker-input {
      color: white !important;
      min-width: 90px;
    }
    .md-icon-button + .md-datepicker-input-container {
      margin-left: 0;
    }
    .date-range-submit {
      min-width: unset;
      min-height: unset;
      line-height: unset;
      margin-left: 16px;
      font-weight: 400;
    }
  }
}

.report-popup-dialog {
  width: 60%;
  height: 90%;
  max-height: 100%;
  .report-app-frame {
    height: 100%;
  }
  .report-header {
    background-color: $darkgreen;
    // padding:10px 0;
    .report-close {
      color: white;
    }
    .header-text {
      color: white;
      padding-left: 10px;
      font-weight: 500;
      font-size: 16px;
    }
  }

  .md-nav-bar {
    // background-color: $darkgreen !important;
    .md-button.md-accent {
      color: $darkgreen !important;
    }
    md-nav-ink-bar {
      background-color: $darkgreen;
      color: $darkgreen;
    }
  }
  .pdf-view {
    background-color: rgb(63, 63, 63);
  }
}

.smad-form {
  padding: 10px;
  border: 2px $green solid;
  md-select .md-select-value {
    font-weight: 500;
  }
}

.md-custom-menu-content {
  .md-date-range-picker__calendar__selected {
    background: rgba(17, 145, 71, 0.9);
    color: white;
  }
}

.download-data-csv {
  color: black;
}

.search-container {
  position: absolute;
  z-index: 0;
  width: 200px;
  top: 53px;
  right: 0;
  border-radius: 5px;
  background-color: white;
  .search_input-bar {
    // padding-top:16px;
    max-height: 30px;
  }
  .text_header {
    font-weight: 500;
    padding-left: 4px;
  }
  md-input-container {
    margin: 0;
    width: 100%;
  }
  .site-list__block {
    max-height: 40vh;
    margin: 8px;
    overflow-x: hidden;
    overflow-y: scroll;
    background-color: rgba(158, 158, 158, 0.25);
    .site-block {
      margin: 8px;
      .site-number {
        font-weight: 500;
        font-size: 14px;
      }
      .site-add {
        font-size: 12px;
      }

      &:focus {
        outline: $green solid 2px;
      }
    }
  }
}

.per-last-quarter {
  font-size: 8px;
}

.ui-date-selector-block {
  padding: 10px 20px 0;
}

.asset-marker__icon {
  position: absolute;
  display: block;
  width: 30px;
  height: 30px;
  left: calc(50% - 10px);
  bottom: -20px;
  border-radius: 50%;
}


.anomaly-marker {
  background-color: rgba(0, 0, 0, 0.75);
  border-top:3px #ff7222 solid ;
  
  border-radius: 4px;
  // box-shadow: 0 1px 50px 0px rgba(235, 11, 11, 0.924);
  position: absolute;
  bottom: 16px;
  width: auto;
  left: 50%;
  transform: translateX(-50%);
  color: rgba(255, 255, 255, 255.95);
  transition: all 0.2s ease;
  z-index: 1;
  padding: 4px;
 
 

  .anomaly-marker__metrics {
    display: flex;
    flex-wrap: nowrap;
    margin: -2px;
  }

  .anomaly-marker_title {
    color: white;
    font-size: 12px;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    margin: 0 0 2px 0;
  }
  .anomaly-marker_type {
    margin-top: -2px;
        width: 100%;
        text-transform: uppercase;
        letter-spacing: 0.75px;
        font-size: 12px;
        font-weight: bold;
        color: white;
  }

  .anomaly-marker__metrics__item {
    flex: 1;
    padding: 2px 4px;
    text-align: center;
    line-height: 12px;
  }

  .anomaly-marker__metrics__item__metric,
  .anomaly-marker__metrics__item__unit {
    font-size: 11px;
    color: rgba(255, 255, 255, 0.85);
  }

  .anomaly-marker__metrics__item__value {
    font-size: 22px;
    font-weight: 500;
    padding: 4px 0;
  }

  .anomaly-marker__metrics__item__value-check {
    color: #63aeff;
  }

  .anomaly-marker__metrics__item__value-zero {
    color: #b0a7a7;
  }

  .anomaly-marker__icon {
    position: absolute;
    display: block;
    width: 26px;
    height: 26px;
    left: calc(50% - 15px);
    bottom: -23px;
    border-radius: 50%;
    z-index: 1000000;
  }

  &:hover {
    z-index: 1000000;
    // background-color: linear-gradient(rgb(246, 242, 242), yellow);
    background: linear-gradient(to top right, #504545, rgba(92, 34, 27, 0.97));
    box-shadow: 0 6px 12px -3px rgba(0, 0, 0, 0.5);

    // &:before {
    //   z-index: 1000000;
    //   border-top-color:  linear-gradient(rgb(249, 244, 244), yellow);
    // }
  }

  &:before {
    position: absolute;
    bottom: -10px;
    left: calc(50% - 10px);
    width: 0;
    height: 0;
    content: "";
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid rgba(0, 0, 0, 0.75);
  }
}

.anomaly-type {
  background-image:  url('/assets/svg/live.svg');
}

.icon-small {
  width: 25px;
  height: 25px;
  margin-bottom: 8px
}

.quality-sensor__icon {
  border: 2px solid #ff5722;
}

.asset-marker {
  background-color: rgba(0, 0, 0, 0.75);
  border-radius: 4px;
  box-shadow: 0 1px 4px 0px rgba(0, 0, 0, 0.8);
  position: absolute;
  bottom: 16px;
  width: auto;
  left: 50%;
  transform: translateX(-50%);
  color: rgba(255, 255, 255, 255.95);
  transition: all 0.2s ease;
  z-index: 1;
  padding: 4px;

  .asset-marker__metrics {
    display: flex;
    flex-wrap: nowrap;
    margin: -2px;
  }

  .asset-marker_title {
    color: white;
    font-size: 12px;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    margin: 0 0 2px 0;
  }

  .asset-marker__metrics__item {
    flex: 1;
    padding: 2px 4px;
    text-align: center;
    line-height: 12px;
  }

  .asset-marker__metrics__item__metric,
  .asset-marker__metrics__item__unit {
    font-size: 11px;
    color: rgba(255, 255, 255, 0.85);
  }

  .asset-marker__metrics__item__value {
    font-size: 22px;
    font-weight: 500;
    padding: 4px 0;
  }

  .asset-marker__metrics__item__value-check {
    color: #63aeff;
  }

  .asset-marker__metrics__item__value-zero {
    color: #b0a7a7;
  }

  .asset-marker__icon {
    position: absolute;
    display: block;
    width: 20px;
    height: 20px;
    left: calc(50% - 10px);
    bottom: -20px;
    border-radius: 50%;
  }

  &:hover {
    z-index: 1000000;
    background-color: rgba(156, 39, 176, 0.97);
    box-shadow: 0 6px 12px -3px rgba(0, 0, 0, 0.5);

    &:before {
      z-index: 1000000;
      border-top-color: rgba(156, 39, 176, 0.97);
    }
  }

  &:before {
    position: absolute;
    bottom: -10px;
    left: calc(50% - 10px);
    width: 0;
    height: 0;
    content: "";
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid rgba(0, 0, 0, 0.75);
  }
}

.asset-marker-red {
  background-color: rgba(216, 38, 45, 0.9);
  &:hover {
    z-index: 2;
    background-color: rgba(216, 38, 45, 0.9);
    box-shadow: 0 6px 12px -3px rgba(0, 0, 0, 0.5);
  }
}
.close-pm__markers {
  position: absolute;
  width: 180px;
  background: rgb(49, 57, 65);
  bottom: 55px;
  left: 190px;
  padding: 6px 8px;
  border-radius: 4px;
  .pm-date__info {
    color: white;
    font-weight: 500;
  }
  md-icon {
    color: white;
  }
  .pm-marker__close {
    margin: 0;
    padding: 0;

    &:hover {
      background-color: $darkred !important;
    }
  }
}

.pm-model__markers {
  position: absolute;
  width: 180px;
  background: rgb(49, 57, 65);
  top: 3px;
  z-index: 100;
  right: 603px;
  padding: 5px 6px;
  border-radius: 4px;
  color: white;
    font-weight: 500;
  md-switch{
    margin:0;
  }
}

.bl-map_block {
  position: absolute;
  // background: rgb(49, 57, 65);
  bottom: 2px;
  left: 4px;
  border-radius: 4px;
}
.live__data-block {
  // position: absolute;
  // right: 0;
  // bottom: 10px;
  background: linear-gradient(#fff, #fff) padding-box,
    linear-gradient(45deg, slateblue, coral) border-box;
  border: 1px solid transparent;
  // border-radius: 16px;

  .live__icon {
    margin-right: 4px;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    display: inline-block;
    background: url("../assets/svg/live.svg") 0px 0px / cover no-repeat;
  }
  .live-value {
    font-weight: 500;
    font-size: 14px;
  }
}

.aura-map__control {
  position: absolute;
  right: 0;
  bottom: 50px;
  .md-button.md-fab {
    z-index: 0;
  }
}

.map-ari__legend {
  background: #c0c0c0;
  padding: 6px 10px;
  border-radius: 6px;
  font-size: 12px;
  .circle {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    display: inline-block;
  }
}


.map-nrw__legend {
  background: #c0c0c0;
  padding: 6px 10px;
  border-radius: 6px;
  font-size: 12px;
  .circle {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    display: inline-block;
  }
}

.close-ari__markers {
  // position: absolute;
  width: 160px;
  background: rgb(49, 57, 65);
  // bottom: 55px;
  // right: 190px;
  padding: 4px 8px;
  border-radius: 20px;
  margin: 0 8px;
  .ari-date__info {
    color: white;
    font-weight: 500;
  }
  md-icon {
    color: white;
  }
  .ari-marker__close {
    margin: 0;
    padding: 0;

    &:hover {
      background-color: $darkred !important;
    }
  }
}

.ari-toggle__group {
  position: absolute;
  border-radius: 4px;
  z-index: 0;
  top: 80px;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;

  .ari-button__group {
    background: #c0c0c0;
    border-radius: 20px;
    padding: 2px;
    .md-button {
      margin: 0;
      background: #c0c0c0;
      border-radius: 20px;
      color: rgb(52, 50, 50);
      text-transform: capitalize;
      min-height: 30px;
      line-height: 30px;
    }
  }
  .ari-toggle__active {
    background: #119147 !important;
    color: white !important;
    transition: all 0.4s ease-in;
  }
}

.ari-tooltip__action-block {
  padding-top: 6px;
  font-size: 12px;
  font-weight: 500;
  .ari-info__tooltip {
    color: #dd2c00;
  }
}

.leaflet-popup-content {
  font-size: 12px !important;
  overflow: auto;
}

.leaflet-div-icon {
  background: none !important;
  border: none !important;
}

// .leaflet-marker-icon:not(:hover) {
//   z-index: 0 !important;
// }

.md-sidenav__dashboard {
  background-color: #e6e5e5;
  min-width: 500px;

  .dashboard-config__header {
    margin-bottom: 10px;
  }
  .user-dashboard__actions {
    padding: 10px 10px 10px 14px;
  }
  .dashboard-form {
    width: 100%;
    md-input-container {
      width: 100%;
    }
  }

  .dashboard-panel__close {
    margin: 4px;
    width: 30px !important;
    height: 30px !important;
    min-height: 30px !important;
    padding: 0;
    &:hover {
      background-color: $darkred !important;
    }
  }

  .ud-selected__area-list {
    max-height: 200px;
    overflow: auto;
  }
  .poly__block {
    background-color: #9c27b0;
    color: #fff;
  }
  .saved-area__list {
    padding-left: 16px;
    ul {
      list-style: unset;
    }
  }
}

.dashboard-asset-select {
  md-select .md-select-value {
    color: rgb(15, 14, 14);
    font-weight: 500;
    max-width: 500px;
  }
}

.asset-filter__value {
  .md-errors-spacer {
    float: unset;
  }
  label {
    font-weight: 500;
  }
}

.download-anomaly__detection {
  md-icon {
    color: white;
    width: 26px;
    height: 26px;
  }
}

.pm_model-block{
  padding:0 12px;
    text-align: center;
    line-height: 12px;
  .pm_model-block__name{
    color: white;
    font-size: 12px;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    margin: 0 0 2px 0;
  }
  .pm_model-block__value{
    font-size: 22px;
    font-weight: 500;
    padding: 4px 0;
  }
}
