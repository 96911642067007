.livehistory-container {
  width: 85% !important;
  height: 90% !important;
  .popup-dialog-headar {
    height: 50px;
    background-color: $secondarygreen;
    .header-text {
      font-size: 20px;
      font-weight: 500;
    }
    .popup-dialog-icon {
      margin: 5px;
      max-height: 40px;
      max-width: 40px;
    }
  }
  .map-radar {
    height: 100%;
  }
  .popup-dialog-close {
    background-color: $orange;
    color: $white;
    font-weight: 500;
    font-size: 1em;
    float: right;
    &:hover {
      background-color: $darkred !important;
    }
  }
  .md-nav-bar {
    // background-color: $darkgreen !important;
    overflow-x: scroll;
    height: fit-content !important;
    .md-button.md-accent {
      color: $green !important;
    }
    md-nav-ink-bar {
      background-color: $green;
      color: $green;
    }
  }

  .history-chart-block {
    margin: 2px 8px;
    overflow: hidden;
  }
  .md-select-value {
    min-width: 100px;
  }
  .forward-options {
    padding-right: 50px;
  }
  .live-download__btn {
    padding-left: 20px;
  }
  .history-download__btn {
    padding-right: 40px;
  }
}

.selectdemoSelectHeader .demo-header-searchbox {
  border: none;
  outline: none;
  height: 100%;
  width: 100%;
  padding: 0;
}
.selectdemoSelectHeader .demo-select-header {
  // box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.1), 0 0 0 0 rgba(0, 0, 0, 0.14),
  //   0 0 0 0 rgba(0, 0, 0, 0.12);
  padding-left: 10.667px;
  height: 48px;
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  width: auto;
}
.selectdemoSelectHeader md-content._md {
  max-height: 240px;
}
