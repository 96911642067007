.header-component {
  min-height: 45px;
  background-color: transparent;
  color: $darkgreen;
  max-height: 45px;
  position: absolute;
  width: 100%;
  z-index: 1;

  .site-name {
    text-transform: uppercase;
    font-weight: 500;
    font-size: 14px;
  }

  .all-sites-down {
    // color: white;
  }

  .switch-site {
    .md-button {
      background: white;
    }
    md-select .md-select-value {
      color: $white;
      font-size: 16px;
      font-weight: 500;
    }

    md-select .md-select-icon {
      color: $white;
    }

    md-input-container {
      margin: 0;
    }
  }
  .header-icon {
    // padding: 0 10px;

    md-icon {
      font-size: 24px;
      height: auto;
      width: auto;
    }
  }

  .sami-icon-button {
    background-color: #07211b;
    color: white;
    span {
      vertical-align: middle;
      font-size: 14px;
    }
    md-icon {
      width: 20px;
      height: auto;
      color: $white;
      min-width: unset;
      min-height: unset;
    }
  }

  .sami-icon-button__active{
    background-color: #119147;
  }



  .ava-icon-button {
    background-color: #101917;
    img {
      height: auto;
      width: 50px;
    }
    &:hover {
      background-color: none;
    }
  }
  .sami-icon:focus,
  .md-icon:focus,
  span:focus,
  button:focus {
    outline: none;
    border: 0;
  }

  .sami-icon {
    margin-left: 5px;
    margin-top: 2px;
    font-weight: 500;
  }

  .middle-column {
    padding-right: 16px;
  }

  .ant-wrapper {
    background-color: $black;
    // min-height: 5vh;
    // min-height: 5vh;
    width: 100%;
    overflow: hidden;
    // margin-top: 5vh;
    max-height: 3.5vmin !important;

    md-menu button {
      border-color: none !important;
    }

    .spacer {
      height: 5vh;
      width: 100vw;
    }
    .ant-container {
      margin-top: -6.5px;
    }

    .ant-text-wrapper {
      position: absolute;
      left: 8%;
      z-index: 4 !important;
      padding: 0 15%;
      margin-right: 100px;
    }

    .ant-controls {
      // min-width: 3vw;
      // // position: static;
      // // left: 0;
      z-index: 10;
      height: 4vh;

      // margin: 0;
      // background-color: #fd6711;
      // border-top-right-radius: 8px;
      // border-bottom-right-radius: 8px;
      // transition: all 10.5s;
      // .md-menu {
      //     margin-right: 10px;
      // }
      button {
        // border-color: $black;
        border-radius: 2px;
        // height: 3vmin;
        // margin: 0vmin 1vmin 0 0;
        // width: 30px;
        // margin-right: 10px;
        text-align: center;
        height: 100%;
        font-size: 22px !important;
        font-weight: 500;
        border: 1px solid blue !important;
        min-width: 35px;
      }

      .ant-controls-primary {
        // transition: all 10.5s;
        // background-color: #313941;
        padding: 5px 15px;

        button {
          // border-color: $black;
          border-radius: 2px;
          height: 3vmin;
          margin: 0.3vmin 0 0 -100px;
        }
      }

      &.closed {
        display: none;
      }
    }

    .ant-clip {
      z-index: 20;

      .ant-controls-secondary {
        height: 5vh;

        .md-button.md-icon-button {
          margin: 0;
        }

        .playPause {
          background-color: #4f4f51;
        }

        button {
          // border-color: $black;
          border-radius: 2px;
          // height: 3vmin;
          // margin: 0vmin 1vmin 0 0;
          // width: 30px;
          // margin-right: 10px;
          text-align: center;
          height: 100%;
          // font-size: 22px !important;
          font-weight: 500;
          border: 1px solid blue !important;
          min-width: 35px;
        }

        i {
          vertical-align: middle;
        }
      }
    }

    .outer {
      position: absolute;
      // width: 2000px;
      left: 10vw;
      // min-width: 100vw;
      z-index: 0;
      bottom: 0;
      // margin-left: -1000px;
      display: flex;
      justify-content: center;
      align-items: center;
      line-height: 36px;
    }

    .left__inner {
      // display: none !important;
      // background: goldenrod;

      padding: 0px 4vmin;
      flex: 1;
      transform: skew(45deg);
      display: flex;
      justify-content: flex-end;
    }

    .right__inner {
      // display: none !important;
      // background: #222;

      padding: 0px 4vmin;
      flex: 1;
      transform: skew(45deg);
    }

    .left__inner,
    .right__inner {
      white-space: nowrap;

      // width: 100vw;
      &.off {
        display: none;
      }

      &.on {
        display: block;
      }
    }

    .left__text,
    .right__text {
      transform: skew(-45deg);

      span {
        color: white;
        // font-weight: 200;
        font-size: 1.5em;
        // text-transform: uppercase;
      }
    }

    .left__text {
      color: #3c3c3c;
    }

    .right__text {
      color: Goldenrod;
    }

    .ant-text-wrapper {
      height: 5vh;
      // overflow: display;
      position: relative;
      white-space: nowrap;
      // min-width: 100vw;
    }

    .ant-text-wrapper .outer,
    .ant-text-wrapper.single-remove-active .outer {
      height: 100%;
      margin: 0;
      //  margin-bottom: .7vmin;
      // line-height: 50px;
      text-align: center;
      /* Starting position */
      -moz-transform: translateX(14.3%);
      -webkit-transform: translateX(14.3%);
      transform: translateX(14.3%);
      /* Apply animation to this element */
      -moz-animation: ant-text-wrapper 280s 0s linear infinite;
      -webkit-animation: ant-text-wrapper 280s 0s linear infinite;
      animation: ant-text-wrapper 280s 0s linear infinite;
    }

    /* Move it (define the animation) */
    @-moz-keyframes ant-text-wrapper {
      0% {
        -moz-transform: translateX(14.3%);
      }

      100% {
        -moz-transform: translateX(-100%);
      }
    }

    @-webkit-keyframes ant-text-wrapper {
      0% {
        -webkit-transform: translateX(14.3%);
      }

      100% {
        -webkit-transform: translateX(-100%);
      }
    }

    @keyframes ant-text-wrapper {
      0% {
        -moz-transform: translateX(14.3%);
        /* Firefox bug fix */
        -webkit-transform: translateX(14.3%);
        /* Firefox bug fix */
        transform: translateX(14.3%);
      }

      100% {
        -moz-transform: translateX(-100%);
        /* Firefox bug fix */
        -webkit-transform: translateX(-100%);
        /* Firefox bug fix */
        transform: translateX(-100%);
      }
    }

    .ant-text-wrapper {
      .single {
        // min-width: 100vw;
        &.short {
          .left__inner,
          .right__inner {
            white-space: nowrap;
            // width: 30vw;
            margin: 0 30vw 0 40vw;
          }

          -moz-animation: ant-text-wrapper 63s 0s linear infinite;
          -webkit-animation: ant-text-wrapper 63s 0s linear infinite;
          animation: ant-text-wrapper 63s 0s linear infinite;

          @keyframes ant-text-wrapper {
            0% {
              -moz-transform: translateX(100%);
              /* Firefox bug fix */
              -webkit-transform: translateX(100%);
              /* Firefox bug fix */
              transform: translateX(100%);
            }

            100% {
              -moz-transform: translateX(-100%);
              /* Firefox bug fix */
              -webkit-transform: translateX(-100%);
              /* Firefox bug fix */
              transform: translateX(-100%);
            }
          }
        }

        &.long {
          -moz-animation: ant-text-wrapper 260s 0s linear infinite;
          -webkit-animation: ant-text-wrapper 260s 0s linear infinite;
          animation: ant-text-wrapper 260s 0s linear infinite;

          @keyframes ant-text-wrapper {
            0% {
              -moz-transform: translateX(100%);
              /* Firefox bug fix */
              -webkit-transform: translateX(100%);
              /* Firefox bug fix */
              transform: translateX(100%);
            }

            100% {
              -moz-transform: translateX(-100%);
              /* Firefox bug fix */
              -webkit-transform: translateX(-100%);
              /* Firefox bug fix */
              transform: translateX(-100%);
            }
          }
        }

        // margin-left: 100vw;
        /* Move it (define the animation) */
        @-moz-keyframes ant-text-wrapper {
          0% {
            -moz-transform: translateX(13%);
          }

          100% {
            -moz-transform: translateX(-105%);
          }
        }

        @-webkit-keyframes ant-text-wrapper {
          0% {
            -webkit-transform: translateX(13%);
          }

          100% {
            -webkit-transform: translateX(-105%);
          }
        }

        @keyframes ant-text-wrapper {
          0% {
            -moz-transform: translateX(13%);
            /* Firefox bug fix */
            -webkit-transform: translateX(13%);
            /* Firefox bug fix */
            transform: translateX(13%);
          }

          100% {
            -moz-transform: translateX(-105%);
            /* Firefox bug fix */
            -webkit-transform: translateX(-105%);
            /* Firefox bug fix */
            transform: translateX(-105%);
          }
        }
      }
    }
  }

  .paused,
  .outer:hover,
  .outer.single:hover {
    -webkit-animation-play-state: paused !important;
    -moz-animation-play-state: paused !important;
    -o-animation-play-state: paused !important;
    animation-play-state: paused !important;
  }

  .ant-text-wrapper.closed {
    min-width: 0 !important;
    max-width: 0 !important;
    overflow: hidden !important;
    display: none;
  }

  .ant-clip {
    margin: 0;
    // transform: scale(0.85);
    transition: all 1.2s;
  }

  .ant-wrapper {
    transition: all 1.2s;
    transition-delay: 1.2s;
  }

  // .sami-icon {
  //     div {
  //         width: 0;
  //         overflow: hidden;
  //         transition: all 1.2s;
  //         transition-delay: 2.4s;
  //     }
  // }
  // .sami-icon.closed {
  //     div {
  //         width: 100%;
  //         overflow: hidden;
  //         // transition: all 2.0s;
  //         // transition-delay: 6.0s;
  //     }
  // }
  .sami-icon:focus,
  .md-icon:focus,
  span:focus,
  button:focus {
    outline: none;
    border: 0;
  }

  .ant-clip.closed {
    padding-right: 8.88vw;

    // opacity: 1;
    // transform: scale(2);
    .ant-controls-secondary {
      display: none !important;
    }
  }

  .ant-wrapper.closed {
    md-menu {
      opacity: 1;
    }

    // height: 0vh !important;
    // margin-bottom: 5vh;
    overflow: hidden;
    background: none;
    // display: none;
  }
}

.sami-list {
  background: none;
  padding: 8px 0 0 0;
}

.bg-S,
.bg-ALL {
  background-color: #3c3c3c !important;
  color: $white;

  // width: 43px;
  &.off {
    background-color: grey !important;
  }

  &.on {
    background-color: #3c3c3c !important;
  }

  &.bg-S:hover {
    color: $black;
    // font-size: 20px;
    // font-weight:500;
  }
}

.bg-A {
  background-color: $red !important;
  color: $white;
  // width: 43px;

  &.off {
    background-color: grey !important;
  }

  &.on {
    background-color: $red !important;
  }

  &.bg-A:hover {
    color: $black;
    // font-size: 20px;
    // font-weight:500;
  }
}

.bg-M {
  background-color: #00b04f !important;
  color: $white;
  // width: 43px;

  &.off {
    background-color: grey !important;
  }

  &.on {
    background-color: #00b04f !important;
  }

  &.bg-M:hover {
    color: $black;
    // font-size: 20px;
    // font-weight:500;
  }
}

.bg-I {
  background-color: $blue !important;
  color: $white;
  // width: 43px;

  &.off {
    background-color: grey !important;
  }

  &.on {
    background-color: $blue !important;
  }

  &.bg-I:hover {
    color: $black;
    // font-size: 20px;
    // font-weight:500;
  }
}

.sami-width {
  width: 43px;
  font-weight: 500;
}

md-backdrop.md-sidenav-backdrop {
  z-index: 69;
}

.aura-side-nav-menu {
  background-color: #0e5d2f;
  color: $white;
  z-index: 70;
  .nav-username {
    padding: 5px 0 4px 10px;
    font-weight: 400;
    letter-spacing: 0.3px;
    text-transform: capitalize;
  }

  .md-bar {
    background-color: $brown;
  }
  md-switch {
    margin: 0;
  }

  md-switch.md-checked.md-primary .md-bar {
    background-color: $silver;
  }

  .md-thumb {
    background-color: $silver;
  }

  md-switch.md-checked.md-primary .md-thumb {
    background-color: $green;
  }

  .md-accordion .expandCollapse {
    width: 15px;
    height: 15px;
    position: relative;
    font-size: 20px;
    font-weight: bold;
    cursor: pointer;
    color: #fff;
    display: block;
    margin-top: -2px;
    margin-left: -2px;
    overflow: hidden;
  }

  .md-accordion .expandCollapse:active {
    border: 0px;
  }

  .md-accordion .expandCollapse:before,
  .md-accordion .expandCollapse:after {
    width: 15px;
    height: 15px;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    line-height: 12px;
    text-align: center;
    -webkit-transition: 0.3s all ease-out;
    transition: 0.3s all ease-out;
  }

  .md-accordion .expandCollapse:before {
    opacity: 1;
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    content: "|";
    margin-top: -3px;
  }

  .md-accordion .expandCollapse:after {
    opacity: 1;
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
    content: "|";
    margin-left: -3px;
  }

  .md-accordion .active:before {
    opacity: 1;
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
    margin-left: 3px;
    margin-top: 0px;
  }

  .md-accordion .dataContent {
    background: rgba(54, 86, 70, 0.95);
    height: 0px;
    overflow: hidden;
    -webkit-transition: 0.3s all ease-out;
    transition: 0.3s all ease-out;
  }

  .md-accordion .activeContent {
    height: fit-content;
    padding: 5px 20px;
    display: block;
  }

  .md-accordion .activeContentLevel2 {
    height: fit-content;
    padding: 6px;
    display: block;
  }

  .md-accordion md-toolbar {
    cursor: pointer;
    border-bottom: 1px solid rgb(63, 107, 181);
  }

  .water-network-nav {
    background-color: $darkgreen !important;
    margin: 0;
    width: 100%;
  }
  .digital-twin-nav {
    background-color: $darkgreen !important;
    margin: 0;
    width: 100%;
    text-align: left;
  }
  .heat-map-nav {
    background-color: $darkgreen !important;
    margin: 0;
    width: 100%;
  }
  .intelligence-nav {
    background-color: $darkgreen !important;
    margin: 0;
    width: 100%;
  }
  .quality-nav {
    background-color: $darkgreen !important;
    margin: 0;
    width: 100%;
    text-align: left;
    a {
      color: white;
    }
  }
  .intelligence-links {
    a {
      color: white;
      font-weight: 500;
      font-size: 14px;
    }
  }

  .smart-meter-links {
    a {
      color: white;
      font-weight: 500;
      font-size: 14px;
    }
    .water-loss-div {
      color: white;
      font-weight: 500;
      font-size: 14px;
    }
    .nav-disabled {
      color: $std-grey;
      font-weight: 500;
      font-size: 14px;
      cursor: not-allowed;
    }
  }
  .active {
    color: $green-shade !important;
    text-decoration: underline;
  }
  .md-button {
    border-radius: unset;
    &:hover {
      color: $green-shade;
    }
  }

  .menu__tier-two {
    min-height: 20px;
    min-width: 88px;
    line-height: 20px;
    font-weight: 500;
    font-size: 14px;
    padding: 0 8px;
  }
  .menu__tier-three {
    color: white;
    a{
      color: white;
    }
    .water-loss-div{
      padding: 6px 0;
      color: white;
      font-weight: 500;
    }
  }
}

.site-select-dialog {
  width: 70% !important;
  min-height: 40% !important;

  .site-select__list {
    padding: 0 16px;
  }
  .site-list__block {
    .md-button {
      text-align: start;
    }
  }
  .site-select-header {
    padding: 8px;
    height: 40px;
    background-color: white;
    color: black;
    .site-select-header__title {
      font-weight: 500;
      font-size: 14px;
      letter-spacing: 0.1px;
      text-transform: uppercase;
    }
  }

  .site-list__block {
    padding: 0 16px;
  }
  md-input-container {
    margin: 0;
    width: 100%;
  }

  .site-selected {
    background-color: $green;
    color: white;
    &:hover {
      background-color: $green;
    }
  }
}
.ava-popup-dialog {
  width: 80% !important;
  height: 85% !important;
  max-width: unset;
  max-height: unset;
  .ava-app-iframe {
    height: 100%;
  }
  .ava-header {
    background-color: #07211b;
    .header-text {
      font-size: 16px;
      font-weight: 500;
      padding-left: 10px;
    }
    .open-tab__btn {
      color: white;
    }
  }
}

.error-toast.md-default-theme .md-toast-content,
.error-toast .md-toast-content {
  background-color: $darkgreen;
  color: $white;
  font-weight: 500;
  font-size: 15px;
  letter-spacing: 0.1px;
  text-transform: initial;
  text-align: center;
  margin-top: 0;
  padding: 0;
  max-height: 20px !important;
  padding: 16px;
  margin-bottom: 40px;

  .md-button {
    color: #f44336;
    text-transform: capitalize;
  }
}

.elv-sidenav {
  min-width: 90vw;
  background-color: rgb(193, 193, 193);
}

md-chips .md-chips {
  box-shadow: none;
  md-chip {
    cursor: pointer;
  }
}

.aq-user__name {
  text-transform: capitalize;
  background: #fff;
  &:hover {
    background: #fff !important;
  }
}

.base-layer-active {
  background-color: rgba(255, 193, 7, 0.82) !important;
}

md-autocomplete#custom-template {
  border-radius: 4px;
  md-autocomplete-wrap {
    border-radius: 4px;
  }
}
.search__aura-site {
  .md-autocomplete-suggestion {
    border-bottom: 1px solid #ccc;
    height: auto;
    padding-top: 4px;
    padding-bottom: 4px;
    white-space: normal;
  }
  .md-autocomplete-suggestion:last-child {
    border-bottom-width: 0;
  }
  .item-title,
  .item-metadata {
    display: block;
    line-height: 2;
  }
}
.aura-map__actions {
  .md-chips {
    font-size: 14px;
    font-weight: 500;
  }
}

.nav-disabled {
  color: $std-grey;
  font-weight: 500;
  font-size: 14px;
  padding: 6px 0;
  cursor: not-allowed;
}

.smart-action__btn {
  md-icon {
    height: 20px;
    width: 20px;
    min-height: 20px;
    min-width: 20px;
  }
  .md-mini {
    line-height: 24px;
    width: 24px;
    height: 24px;
  }
}

/**
* Anomaly Style 
*/

.select-contain {
  border-radius: 20px;
}
.custom-select  {
  display: inline-flex;
  align-items: center;
  border-radius: 20px;
  height: 30px;
  line-height: 27px;
  margin: 0;
  padding-right: 5px;
}

.custom-select-red {
  border: 2px solid #f44336;
  background: linear-gradient(to right, #f44336, #ffffff);
}

.custom-select-gray {
  border: 2px solid #cccccc;
  background: linear-gradient(to right, #cccccc, #ffffff);
}


.custom-select .md-select-value {
  display: inline-flex;
  align-items: center;
  // border-radius: 20px;
  // border: 2px solid #f44336;
  height: 27px;
  line-height: 27px;
  margin: 0;
  border-bottom: none !important;
  // background: linear-gradient(to right, #f44336, #ffffff);
}

.custom-select .md-select-value span {
  color: black;
  font-weight: bold;
  white-space: nowrap;
  margin: 7px;
}

.custom-select .md-select-value::after {
  display: none;
}

.custom-select md-select-menu {
  border-radius: 10px;
}

.custom-select .md-option {
  padding: 10px 20px;
}

.custom-arrow {
  display: inline-block;
  margin-left: 3px;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid black;
  width: 0;
  height: 0;
}
.custom-select.red  {
  background: linear-gradient(to right, #f44336, #ffffff);
  border-color: #f44336;
}

.custom-select.gray  {
  background: linear-gradient(to right, #cccccc, #ffffff);
  border-color: #cccccc;
}

md-select {
  margin: 0 !important;
}

md-select-menu {
  border-radius: 20px;
  border: 2px solid #cccccc;
  margin: 30px;
}

.custom-select-wrapper {
  display: flex;
  align-items: center;
}

.custom-select-wrapper .anomaly-info {
  margin-left: 8px;
}

.anomaly-warning-icon {
  position: absolute;
  display: block;
  width: 20px;
  height: 20px;
  left: calc(50% - 10px);
  bottom: -20px;
  border-radius: 50%;
}

.beta-name {
  text-transform: capitalize;
  font-size: 12px;
}

.custom-switch .md-container {
  display: none; 
}
.custom-switch .md-thumb,
.custom-switch .md-bar {
    display: none !important; 
}

.custom-switch .md-label {
    cursor: pointer;
}

.checked-switch .md-label {
    text-decoration: underline;
    color: #97f979 !important;
}

.anomaly-text {
  padding: 6px 0;
    font-weight: 500;
}

.anomaly-info {
  background-color: transparent; 
  border: 2px solid rgb(105, 100, 100); 
  color: #000; 
  border-radius: 50%; 
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center; 
  font-size: 16px; 
  font-weight: bold;
  cursor: pointer; 
  padding: 1;
  outline: none; 
  
}

.anomaly-info:hover {
  background-color: rgba(0, 0, 0, 0.1); 
}

// md-select-menu md-option .md-icon {
//   display: none !important;
// }

// md-select-menu md-option .md-container {
//   display: none !important;
// }


/* Custom Dropdown Styles */
.dropdown {
  position: relative;
  border: 2px solid #f44336;
  border-radius: 20px;
  background: linear-gradient(to right, #f44336, #ffffff);
  cursor: pointer;
  padding: 10px;
}


.dropdown-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
}

.dropdown-arrow {
  margin-left: 10px;
}

.dropdown-content {
  position: absolute; /* Ensure dropdown is positioned relative to the dropdown header */
  top: 85%; /* Position below the dropdown header */

  background-color: white;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0,0,0,0.2);
  overflow-y: auto;
  z-index: 1000;
  max-height: 200px; /* Set maximum height */
  height: auto; /* Allow height to adjust based on content */
  width: fit-content;
}

/* Additional styling for dropdown item spacing */
.dropdown-item {
  padding: 10px;
  display: flex;
  align-items: center;
}

.dropdown-item:last-child {
  border-bottom: none;
}

.dropdown-item label {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.dropdown-item input[type="checkbox"] {
  margin-right: 10px;
}

.dropdown-item:hover {
  background-color: #f5f5f5;
}
.dropdown-text {
    color: black;
    font-weight: bold;
    white-space: nowrap;
    margin: 7px;
  
}

//check box 
.custom-checkbox {
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  margin-bottom: 12px;
}

.custom-checkbox input[type="checkbox"] {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 20px;
  height: 20px;
  border: 2px solid #3f51b5;
  border-radius: 3px;
  background-color: white;
  position: relative;
  cursor: pointer;
  outline: none;
  margin-right: 8px;
  box-shadow: none;
}

.custom-checkbox input[type="checkbox"]:hover {
  border-color: #303f9f; /* Darker border color on hover */
}

.custom-checkbox input[type="checkbox"]:focus {
  outline: none; /* Remove default focus outline */
  box-shadow: 0 0 0 2px rgba(63, 81, 181, 0.5); /* Optional: Add a soft shadow for focus state */
}

.custom-checkbox input[type="checkbox"]:checked {
  background-color: #3f51b5;
  border-color: #3f51b5;
}

.custom-checkbox input[type="checkbox"]:checked::after {
  content: '';
  position: absolute;
  left: 6px;
  top: 2px;
  width: 6px;
  height: 12px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.custom-checkbox img {
  margin-left: 8px;
}

.custom-checkbox span {
  color: #3f51b5;
  font-size: 14px;
}
