.report-title {
    text-align: center;
}

.report-file {
    width: 100%;
}

.chart-two {
    margin: auto;
    margin-top: -401px;
    margin-left: 39%;
}

.download-btn {
    margin-bottom: 50px;
    .pdf-btn {
        position: absolute;
    }
}

.trend-up {
    color: $green;
}

.trend-down {
    color: $darkred;
}

#myDiv1 .legend .traces:nth-of-type(1) .legendtoggle {
    display: none;
}

#myDiv1 .legend .traces:nth-of-type(1) .legendtext {
    cursor: default;
}

#myDiv1 .legend .traces:nth-of-type(2) .legendtoggle {
    display: none;
}

#myDiv1 .legend .traces:nth-of-type(2) .legendtext {
    cursor: default;
}

.trend-table {
    text-align: center;
}

.chart-bottom {
    background-color: $silver;
}

.false-dr {
    color: $darkred;
    animation: blink 3s linear infinite;
}

.true-dr {
    color: $green;
    // animation: blink 2.5s linear infinite;
}

// @keyframes blink {
//     10% {
//         opacity: 0.1;
//     }
// }
.alert-no {
    display: block;
    text-align: center;
    background-color: red;
    padding: 0;
    padding-left: 10%;
}

.ari-name {
    text-transform: capitalize;
}

.some-pdf-container {
    width: 100%;
    height: 100%;
}