.setting-container {
    width: fit-content;

    .alert-header {
        height: 40px;

        // background-image: linear-gradient(to left, #8b9dcc, #7083a7, #586a84, #435161, #313941);
        background-color: $darkgreen;
        color: white;

        .alert-name {
            font-size: 20px;
            letter-spacing: 0.2px;
            font-weight: 500;
        }
    }

    .catalog-header {
        height: 40px;
        background-color: $darkgreen;
        color: white;
        margin-bottom: 10px;

        .alert-name {
            font-size: 20px;
            letter-spacing: 0.2px;
            font-weight: 500;
        }
    }

    .message {
        color: $darkred !important;
    }

    .md-button.md-raised {
        color: $white;
        background-color: $green;
    }

    md-checkbox.md-checked .md-icon {
        background-color: $green;
    }

    md-input-container {
        margin-bottom: 0;
        margin-top: 10px;
    }

    md-select .md-select-value {
        text-align: center;
    }

    // .card-height {
    //     max-height: 26vh;
    //     overflow-y: scroll;
    // }

    // .material-icons .icon-md {
    //     margin-top: 30px;
    //     margin-left: 20px;
    //     font-size: 18px !important;
    // }

    md-checkbox[disabled].md-checked .md-icon {
        background-color: $green;
    }

    h4 {
        text-decoration: underline;
    }

    md-input-container .md-input[disabled] {
        color: $brown;
    }

    md-input-container .md-input {
        background-color: transparent;
    }



    .sms-check {
        margin-left: 20px !important;
    }

    .email-input {
        margin-left: -15px !important;
        padding-right: 10px;
    }

    .md-user {
        color: $white;
        float: right;
        margin: 0;
        background-color: $brown;

        &:hover {
            background-color: $green !important;
        }
    }


    md-progress-circular path {
        stroke: $darkgreen;
    }

    .md-button.md-raised[disabled] {
        background-color: rgba(0, 0, 0, 0.12);
        color: black;
    }

    md-expansion-panel.md-open {
        margin: 0;
    }

    md-expansion-panel-collapsed,
    .md-expansion-panel-header-container {
        background-image: linear-gradient(to bottom, #636363, #58585a, #4b4d52, #3e4349, #313941);
        color: white;
        font-size: 16px;
        font-weight: 500;
    }

    .card-out {
        min-width: 40vw;
    }

    .sami-form-footer {
        background-color: $darkgreen;
        border: 1px $green-shade solid;
        .md-button.md-raised[disabled] {
            background-color: grey;
            color: black;
        }
    }

    md-tabs-wrapper {
        position: fixed;
        top: 0px;
        left: 0px;
        z-index: 1000;
        width: 100%;
    }

    md-tab-content {
        padding-top: 48px;
    }
    .card-table{
        table.md-table tbody.md-body>tr.md-row:nth-child(odd)>td {
            background-color: #e6e5e5 !important;
        }
        table.md-table tbody.md-body>tr.md-row>td {
            font-weight: 500;
        }
        table.md-table:not(.md-row-select) td.md-cell:first-child,td.md-cell:last-child{
            text-transform: capitalize;
        }
        table.md-table th.md-column {
            color: $black;
            // font-size: 16px;
            text-transform: uppercase;
        }
    }
    
}


.anomaly-table {
    width: 100%;
    border-collapse: collapse;
    padding: 8px;
}
.anomaly-table-th, .anomaly-table-td {
    padding: 12px;
    border-bottom: 1px solid #ddd;
    text-align: left;
}
.anomaly-table-th {
    background-color: #f2f2f2;
    color: #333;
    text-transform: uppercase;
    text-align: left !important;
}
.anomaly-table-td {
    color: #666;
    margin: 16px;
}
.anomaly-table-tr:hover {
    background-color: #f5f5f5;
}
.anomaly-name {
    display: flex;
    justify-content: end;
    width: 100%;

    font-size: 20px;
    letter-spacing: 0.2px;
    font-weight: 500;

}
