.site-data-container {
  .site-data-header {
    background-color: #119147;
    height: 48px;
    color: white;
    padding: 0 16px;
    .site-data__text {
      font-size: 20px;
      font-weight: 500;

      letter-spacing: 0.5px;
    }
  }
  .site-data__container {
    padding: 6px;
  }

  .site-data__select-container{
    md-select{
      background: white;
      font-size: 14px;
      font-weight: 500;
    }
  }

  .site-data-table__container {
    background: white;
    height: 80vh;
    overflow: scroll;
    .table__container {
      max-height: 72vh;
      table.md-table tbody.md-body > tr.md-row:nth-child(odd) > td {
        background-color: #e7e7e7 !important;
      }
      table.md-table tbody.md-body > tr.md-row {
        height: 40px;
      }
      table.md-table tbody.md-body > tr.md-row > td {
        font-weight: 500;
        white-space: nowrap;
      }
      table.md-table th.md-column {
        color: $black;
        font-size: 12px;
      }
      table.md-table:not(.md-row-select)
        td.md-cell:nth-child(n + 2):nth-last-child(n + 2),
      table.md-table:not(.md-row-select)
        th.md-column:nth-child(n + 2):nth-last-child(n + 2) {
        padding: 0 12px 0 0;
      }
    }
    .label {
      color: black;
    }
  }
  md-icon{
    color: white;
  }
}
