.aq-video-container {
  .aq-video-header{
    background-color: #119147;
    height: 48px;
    color: white;
    padding: 0 16px;
    .aq-video__text {
      font-size: 20px;
      font-weight: 500;
      
      letter-spacing: 0.5px;
      .elv-icon-page {
        margin: 0 0 5px 0;
        width: 28px;
        height: 28px;
      }
    }
  }
  .elv-video__container {
    padding: 6px;
  }
  .video-container {
    // max-height: 60%;
    overflow: hidden;
    border: 1px rgba(126, 126, 126, 0.537) solid;
  }
  .video-container video {
    min-width: 100%;
    max-height: 60vh;
  }

  .image-w {
    position: relative;
    .image {
      display: block;
      width: 100%;
      height: 130px;
    }
    .cover {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      color: rgba(255, 255, 255, 0);
      text-align: center;
      transition: all 1s linear;
      cursor: pointer;
    }
    &:hover .cover {
      background-color: rgba(0, 0, 0, 0.4);
      transition: all 0.2s linear;
    }

    &:hover .elv-play__icon {
      color: #119147;
    }

    .elv-play__icon {
      font-size: 30px;
      position: absolute;
      left: 0;
      bottom: 10px;
      text-align: center;
    }
  }
  .video-list__content {
    padding: 4px;
    .video-list__content-tilte {
      font-size: 14px;
      font-weight: 500;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      letter-spacing: 0.3px;
    }
    .video-list__app-name {
      font-size: 12px;
      font-weight: 500;
      color: rgb(96, 96, 96);
      margin: 4px 0;
      text-transform: uppercase;
    }
    .video-list__new-tag {
      padding: 2px;
      background: #e5e9e7;
      color: rgb(0, 0, 0);
      font-weight: 700;
      font-size: 11px;
      border-radius: 2px;
    }
  }
  .elv-content__block {
    padding: 8px 16px;
    // border-bottom: 1.2px #9e9e9e solid;
    .elv-content__block-title {
      font-size: 18px;
      font-weight: 500;
      padding: 8px 0;
      letter-spacing: 0.3px;
    }
    .elv-content__block-date {
      color: rgb(96, 96, 96);
      font-weight: 500;
      font-size: 14px;
    }
  }

  .isCorrect_true {
    background-color: green;
    color: white;
  }

  .isCorrect_false {
    background-color: red;
    color: white;
  }
  .elv-quiz__header {
    text-align: center;
    padding: 10px 0;
    font-weight: 500;
    font-size: 24px;
    letter-spacing: 0.3px;
  }
  ol {
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 0.3px;
    margin-left: 30px;
  }
  .options-block {
    font-size: 16px;
    font-weight: 400;
  }
  .question {
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 0.3px;
    padding-bottom: 1px;
  }
  .elv-result__block {
    padding-bottom: 14px;
    .elv-result__text {
      font-weight: 400;
      font-size: 18px;
      font-weight: 500;
      padding-top: 10px;
    }
    .elv-fail__text {
      padding: 10px 0;
      font-size: 16px;
      font-weight: 500;
    }
    .elv-pass__text {
      padding: 10px 0;
      font-size: 16px;
      font-weight: 500;
      color: green;
    }
  }
  .elv-quiz__submit {
    padding-bottom: 14px;
  }
  .elv-selected {
    border: 2px #119147 solid;
    will-change: transform, box-shadow;
    transform: matrix(1.012, 0, 0, 1.012, 0, 0);
  }
  md-card {
    outline: none;
  }
  md-icon{
    color: white;
  }
}
