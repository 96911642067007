.quality-select {
  .quality-date-range {
    padding-left: 20px;
    color: white;
    font-weight: 500;
  }
  .quality-chart-block {
    margin: 2px 8px;
    overflow: hidden;
  }


  md-progress-circular path {
    stroke: white;
  }
  .submit-quality__btn {
    color: white;
  }
  .download-quality__btn {
    color: white;
    padding-right: 8px;
  }
  .info-quality__btn {
    padding-right: 8px;
  }
}
.info__btn {
  padding-right: 8px;
}

.quality-sample-select {
  md-select .md-select-value {
    color: white;
    font-weight: 500;
    max-width: 500px;

  }
}

.cct-table__container {
  background: white;
  height: 56vh;
  overflow: scroll;
  .table__container {
    max-height: 48vh;
    table.md-table tbody.md-body > tr.md-row:nth-child(odd) > td {
      background-color: #e7e7e7 !important;
    }
    table.md-table tbody.md-body > tr.md-row {
      height: 40px;
    }
    table.md-table tbody.md-body > tr.md-row > td {
      font-weight: 500;
      white-space: nowrap;
    }
    table.md-table th.md-column {
      color: $black;
      font-size: 12px;
    }
    table.md-table:not(.md-row-select)
      td.md-cell:nth-child(n + 2):nth-last-child(n + 2),
    table.md-table:not(.md-row-select)
      th.md-column:nth-child(n + 2):nth-last-child(n + 2) {
      padding: 0 12px 0 0;
    }
  }
  .label {
    color: black;
  }
}
.md-container-ignore {
  display: none;
}

.site-city__selection {
  height: 100%;
  width: 100%;
  padding: 10px 0 0 10px;
  margin: 0;
  text-align: start;
}

.select__all-options {
  margin-left: 30px;
}
