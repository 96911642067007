
$green:#119147;
$white: #ffffff;
$darkred: #D41F22;

body {
    margin: 0;
    padding: 0;
    overflow-x: hidden;
}
table {
    width: 100%;
    border-collapse: collapse;
}
th, td {
    // padding: 12px;
    border-bottom: 1px solid #ddd;
    text-align: left;
}
th {
    background-color: #f2f2f2;
    color: #333;
    text-transform: uppercase;
    text-align: left !important;
}
td {
    color: #666;
    // margin: 16px;
}
tr:hover {
    background-color: #f5f5f5;
}

.form-input {
  padding-right: 11px;
}

.disabled-input {
  opacity: 0.5;
  pointer-events: none;
}
.plot-button {
  display: inline-block;
  position: relative;
  cursor: pointer;
  min-height: 30px;
  min-width: 40px;
  border: 0;
  font-weight: 500;
  font-size: 14px;
  font-style: inherit;
  color: rgb(255, 255, 255);
  background-color: rgb(255, 87, 34);
}

.centered-message {
  color: gray; 
  text-align: center; 
  font-size: 20px; 
  font-weight: bold;
  margin-top: 20px; 
}


// .live-day-container {
//     min-width: 95% !important;
//     min-height: 90% !important;
//     .popup-dialog-headar {
//       height: 50px;
//       background-color: #313941;
//       .header-text {
//         font-size: 20px;
//         font-weight: 500;
//       }
//       .popup-dialog-icon {
//         margin: 5px;
//         max-height: 40px;
//         max-width: 40px;
//       }
//     }
//     .map-radar {
//       height: 100%;
//     }
//     .popup-dialog-close {
//       background-color: #7E3112;
//       color: $white;
//       font-weight: 500;
//       font-size: 1em;
//       float: right;
//       &:hover {
//         background-color: $darkred !important;
//       }
//     }
//     .md-nav-bar {
//       // background-color: $darkgreen !important;
//       overflow-x: scroll;
//       height: fit-content !important;
//       .md-button.md-accent {
//         color: $green !important;
//       }
//       md-nav-ink-bar {
//         background-color: $green;
//         color: $green;
//       }
//     }
  
//     .day-chart-block {
//       margin: 2px 8px;
//       overflow: hidden;
//     }
//     .md-select-value{
//       min-width:100px;
//     }
//     .forward-options{
//       padding-right: 50px;
//     }
//     .live-download__btn{
//       padding-left:20px;
//     }
//     .day-download__btn{
//       padding-right: 40px;
//     }
  
//     .day-sensor__select{
//       max-width: 300px;
//     }
//   }
  
  .md-date-range-picker__calendar__grid {
    font-size: 14px;
  }

  .hide-checkbox .md-container {
    display: none;
}

.history-form-container{
  padding:8px;
}



  