.notification-container {
  min-width: 85%;
  min-height: 85%;
  .sami-history__header {
    background-color: $green;
    color: white;
    padding-left: 16px;
    .sami-history__header-text {
      font-size: 16px;
      font-weight: 500;
    }
    .md-button.md-fab:not([disabled]):hover {
      background-color: #f44336 !important;
    }
  }
  .sami-date__range{
    padding-left:16px;
  }
  .pagination-table {
    color: white !important;
    background-color: #313941 !important;
    font-size: 16px;
    // height: 40px;
    md-icon {
      margin: 0;
      color: white;
    }
    md-select.md-table-select {
      margin: 20px 0 26px 0;
    }
    .md-button[disabled] {
      md-icon {
        color: red !important;
      }
    }
  }
  md-select.md-table-select > .md-select-value > span > .md-text {
    color: $white;
    font-weight: 500;
  }
  table.md-table tbody.md-body > tr.md-row:nth-child(odd) > td {
    background-color: #e6e5e5 !important;
  }
  table.md-table tbody.md-body > tr.md-row > td {
    font-weight: 500;
    text-transform: capitalize;
  }
  table.md-table th.md-column {
    color: $black;
    font-size: 16px;
    text-transform: uppercase;
  }
  .no-results {
    padding: 0 16px;
  }
  .analysis-chart iframe {
    height: 60vh;
  }
  .notification-header {
    background-color: #313941;
    color: white;
    height: 40px;
    .expand-icon {
      margin-left: 10px;
    }
    .alert-download__csv{
      padding-right: 20px;
    }
    md-icon {
      color: white;
    }
    .notification-title {
      font-size: 20px;
      font-weight: 500;
      letter-spacing: 0.2px;
      margin-left: 5px;
    }
  }
  .catalog-icon {
    margin-right: 10px;
  }
  md-content {
    background: none;
  }
}
